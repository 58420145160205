import { Component, OnInit } from '@angular/core';
import { Product } from '../../../models/product';
import { ProductService } from 'src/app/services/product.service';
import { first } from 'rxjs/operators';

import 'rxjs/add/operator/map'

import { AddProductComponent } from '../add-product/add-product.component';
import { AotCompiler } from '@angular/compiler';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  products: Product[];
  now_admin_peek_product: Product;


  constructor(private productService: ProductService, private addProductComponent : AddProductComponent ) { }

  ngOnInit() {
    /** pipe 를 사용하게 되면 실시간 업데이트가 되지 않는다. */
    // this.productService.getProducts().pipe(first()).subscribe(products => {
    //   console.log("improduct 1");
    //   this.products = products;
    // })

    this.productService.getProducts().subscribe(products => {
      this.products = products;
      console.log("Product 0 colors : ", products[0].colors)
    })

  }

  deleteProduct(event, product) {
    console.log("Clicked Product : " , product);
    this.productService.deleteProduct(product);
  }

  // 더블클릭해서 현재 선택된 프로덕트를 가져온다.
  nowSelectProduct(event, product) {
    console.log(product);
    this.now_admin_peek_product = product;
    this.productService.setAdminNowPeekProduct(product);
  }
}
