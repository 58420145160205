import { Component, OnInit, ViewChild } from '@angular/core';

import { Main } from 'src/app/models/main';
import { MainService } from 'src/app/services/main.service';
import { first } from 'rxjs/operators';
import { DragScrollComponent } from 'ngx-drag-scroll';


@Component({
  selector: 'app-carousel-con',
  templateUrl: './carousel-con.component.html',
  styleUrls: ['./carousel-con.component.scss']
})
export class CarouselConComponent implements OnInit {
  Mains: Main[];

  @ViewChild('car', { read: DragScrollComponent }) ds: DragScrollComponent;
  constructor(private MainService: MainService) { }

  ngOnInit() {
    this.MainService.getMains().pipe(first()).subscribe(Mains => {
      this.Mains = Mains;
    })
  }

  moveLeft() {
    this.ds.moveLeft();
  }

  moveRight() {
    this.ds.moveRight();
  }

  moveTo(index) {
    this.ds.moveTo(index);
  }

}
