import { fromEvent } from "rxjs";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";

type Target = Document | Element;

@Injectable({
    providedIn: "root"
})
export class ScrollleftService {
    constructor() {
        // ...
    }

    public getScroll( node: Target = document ) : number {
        var currentScroll = this.getCurrentScroll( node );
        var maxScroll = this.getMaxScroll( node );
        var percent = ( currentScroll / Math.max( maxScroll, 1 ) );
        percent = Math.max( percent, 0 );
        percent = Math.min( percent, 1 );
        return( percent * 100 );

    }


    public getScrollAsStream( node: Target = document ) : Observable<number> {
        if ( node instanceof Document ) {
            var stream = fromEvent( window, "documnet:scroll" ).pipe(
                map(
                    ( event: UIEvent ) : number => {
                        return( this.getScroll( node ) );
                    }
                )
            );

        } else {
            var stream = fromEvent( node, "scroll" ).pipe(
                map(
                    ( event: UIEvent ) : number => {
                        return( this.getScroll( node ) );
                    }
                )
            );

        }
        return( stream );
    }


    private getCurrentScroll( node: Target ) : number {
        if ( node instanceof Document ) {
            return( window.pageXOffset );
        } else {
            return( node.scrollLeft );

        }

    }


    private getMaxScroll( node: Target ) : number {
        if ( node instanceof Document ) {
          var Scrollwidth = Math.max(
            node.body.scrollWidth,
            node.body.offsetWidth,
            node.body.clientWidth,
            node.documentElement.scrollWidth,
            node.documentElement.offsetWidth,
            node.documentElement.clientWidth
        );

        var Clientwidth = node.documentElement.clientWidth;

        return( Scrollwidth - Clientwidth );

    } else {
        return( node.scrollWidth - node.clientWidth );

    }
    }

}
