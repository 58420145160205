import { Component, OnInit, Input, Inject } from '@angular/core';
import { Album } from 'src/app/models/album';
import { AlbumService } from 'src/app/services/album.service';

import { AngularFireUploadTask, AngularFireStorage } from '@angular/fire/storage';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { inject } from '@angular/core/testing';

@Component({
  selector: 'app-album-add',
  templateUrl: './album-add.component.html',
  styleUrls: ['./album-add.component.scss']
})
export class AlbumAddComponent implements OnInit {

  constructor(
    private albumService: AlbumService,
    private storage: AngularFireStorage,
    // private db: AngularFirestore,
    private dialogRef: MatDialogRef<AlbumAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  state;  // 0. ADD 1. MODIFY 2. DELETE
  album: Album = {
    name: '',
    script: '',
    tag: [],
    thumbnail: '',
    files: []
  };

  thumbnail_file: File;  // Thumbnail 에 들어갈 file 을 받을 변수

  download_files = []; // Download 받게 될 파일들.
  download_names = []; // Download 받게 될 파일의 이름들.


  ngOnInit() {
    this.state = this.data.state;

    // console.log(this.data.selectedAlbum);

    if (this.state == 1) {
      this.Set_Modify_Album(this.data.selectedAlbum);
    }

    if (this.state == 2) {
      let album = this.data.album;

      this.delete_list.push(album.thumbnail); // thumbnail 삭제 리스트에 등재

      let keys = [];

      for (var i = 0; i < album.files.length; i++) {
        keys.push(Object.keys(album.files[i]));
      }

      for (var i = 0; i < keys.length; i++) {
        this.delete_list.push(album.files[i][keys[i]]);
      }

      //console.log("delete list : ", this.delete_list);
    }
  }

  // Modify 로 들어오게 되면 album 형태로 바꿔서 준비한다.
  Set_Modify_Album(input: Album) {
    this.album = input;
    for (var i = 0; i < this.album.tag.length; i++) {
      this.Add_Tag();
      this.tags[i].value = this.album.tag[i];
    }

    for (var i = 0; i < this.album.files.length; i++) {
      this.Add_Files();
      this.download_names[i].value = Object.keys(this.album.files[i])[0];
      this.download_files.push(undefined);
      // this.tags[i].value = this.album.tag[i];
    }
  }

  // 추가
  Add() {
    //console.log("Add Button Click");
  }

  // 삭제
  Delete() {
    //console.log("Delete Button Click");
  }

  // Thumbnail 이 등록되었을때.
  onDrop_Thumbnail(file: File) {
    this.thumbnail_file = file;
  }

  //#region #Tag Control

  tags = []; // String Array 는 사용할 수 없어서 임시 변수로 만들어두고 저장해야한다.

  Add_Temp_Tags() {
    this.tags.push({ value: '' });
  }

  Add_Tag() {
    this.tags.push({ value: '' });
  }

  Delete_Tag(index) {
    if(this.state == 0){
      this.tags.splice(index, 1);
    } else if ( this.state == 1){
      // this.selectedAlbum.tag.splice(index, 1);
    }
  }

  // 임시로 저장해둔 태그를 Apply 해준다.
  async Tag_Apply() {
    //console.log("Tags : ", this.tags);
    this.album.tag = [];
    for (var i = 0; i < this.tags.length; i++) {
      await this.album.tag.push(this.tags[i].value);
    }
    //console.log("Album Tag : ", this.album.tag);
    return;
  }

  //#endregion

  //#region #File Control
  Add_Files() {
    // this.album.files.push({"":""});
    this.download_names.push({value:''});
    console.log("download ", this.download_names);
  }

  Delete_Files(index) {
    this.download_names.splice(index, 1);
    this.download_files.splice(index, 1);
  }


  // Download file
  onDrop_DownloadFile(file: File, index = 0) {
    if(this.state == 0){
      this.download_files.push(file);
    } else if ( this.state == 1){
      this.download_files[index] = file;
    }
  }
  //#endregion



  task: AngularFireUploadTask;
  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL;

  /**
   * ADD Button 을 누르면 Thumbnail 과 file 들을 Upload 해준다.
   */
  async Add_Button() {
    await this.Tag_Apply(); // Tag 를 Album 형태로 바꿔서 저장해준다.
    await this.Upload_Start(this.thumbnail_file[0], 'thumbnail'); // Thumbnail 을 저장해준다.
    for (var i = 0; i < this.download_names.length; i++) {
      await this.Upload_Start(this.download_files[i][0], 'download', i);
    }
    this.albumService.AddAlbum(this.album);
    this.dialogRef.close();
  }

  async Modify_Button() {
    if(this.thumbnail_file == undefined){
    } else {
      // 기존에 있던거 삭제하고 그 다음에 새로 등록해줘야함.
      // 1. 기존 파일 삭제
      await this.storage.storage.refFromURL(this.album.thumbnail).delete();
      // 2. 파일 업로드
      await this.Upload_Start(this.thumbnail_file[0], 'thumbnail');
    }

    await this.Tag_Apply();

    // this.album.files = [];

    for (var i = 0; i < this.download_names.length; i++) {
      await this.Upload_File_Modify('download', i);
    }

    this.albumService.ModifyAlbum(this.album);
    this.dialogRef.close();

    // 태그 새로 바꿔줘야함.

    // files 가 제일문제새롭게 넣어줘야함...

  }

  Upload_File_Modify(type, index = 0) {
    return new Promise<void>((resolve, reject) => {
      if (this.download_files[index] == undefined) {
        // let key = this.download_names[index].value;
        // let json_string = '{"' + key + '":"' + this.data.selectedAlbum.files[index][key] + '"}';
        // let json = JSON.parse(json_string);
        // this.album.files.push(json);
        resolve();
      } else {
        const path = "album/" + this.album.name + "/" + type + "_" + `${Date.now()}_${this.download_files[index][0].name}`;
        const ref = this.storage.ref(path);
        console.log(this.data.selectedAlbum.files[index][this.download_names[index].value]);
        this.storage.storage.refFromURL(this.data.selectedAlbum.files[index][this.download_names[index].value]).delete();
        this.storage.upload(path, this.download_files[index][0]).then(
          async () => {
            let value = await ref.getDownloadURL().toPromise();


            switch (type) {
              case 'thumbnail':
                this.album.thumbnail = value;
                break;
              case 'download':

                let key = this.download_names[index].value;
                let testobject: Object;
                testobject = { 'asdf': 'zzezdzd' };
                testobject[key] = value;

                let json_string = '{"' + key + '":"' + value + '"}';
                //console.log("string is : ", json_string);
                let json = JSON.parse(json_string);
                // testobject[key] = value;
                // this.album.files.push(json);
                this.album.files[index] = json;
                break;
            }
            resolve();
          }
        )
      }
    });
  }


  Upload_Start(file, type, index = 0) {
    return new Promise<void>((resolve, reject) => {
      const path = "album/" + this.album.name + "/" + type + "_" + `${Date.now()}_${file.name}`;

      const ref = this.storage.ref(path);

      this.storage.upload(path, file).then(
        async () => {
          let value = await ref.getDownloadURL().toPromise();


          switch (type) {
            case 'thumbnail':
              this.album.thumbnail = value;
              break;
            case 'download':
              let key = this.download_names[index].value;
              let testobject: Object;
              testobject = { 'asdf': 'zzezdzd' };
              testobject[key] = value;

              let json_string = '{"' + key + '":"' + value + '"}';
              //console.log("string is : ", json_string);
              let json = JSON.parse(json_string);
              // testobject[key] = value;
              this.album.files.push(json);
              break;
          }
          resolve();
        }
      )
    });
  }

  delete_list = [];

  Real_Delete() {
    // 여기에 삭제되는 시퀀스가 들어가면 됌.
    for (var i = 0; i < this.delete_list.length; i++) {
      this.storage.storage.refFromURL(this.delete_list[i]).delete();
    }
    this.albumService.DeleteAlbum(this.data.album);
    this.dialogRef.close();
  }
}
