import { Component, OnInit } from '@angular/core';
import { Product } from 'src/app/models/product';
import { ProductService } from 'src/app/services/product.service';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
@Component({
  selector: 'app-product-detail-view',
  templateUrl: './product-detail-view.component.html',
  styleUrls: ['./product-detail-view.component.scss']
})
export class ProductDetailViewComponent implements OnInit {
  constructor( private productService: ProductService, private route: ActivatedRoute ) { }

  now_select_product: Product;

  ngOnInit() {
    window.scrollTo(0, 0);
    //this.now_select_product =  this.productService.getSelectProduct();
    
    this.route.paramMap.subscribe(params => {
      this.productService.getProductInfoByCollectionAndName(params.get('collection'), params.get('name')).pipe(first()).subscribe(products => {
        this.now_select_product = products[0];
        console.log(this.now_select_product);
      });
    });
  }
}
