import { Component, OnInit, Inject } from '@angular/core';
import { Block } from 'src/app/models/block';
import { BlockService } from 'src/app/services/block.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-blocks',
  templateUrl: './add-blocks.component.html',
  styleUrls: ['./add-blocks.component.css']
})
export class AddBlocksComponent implements OnInit {

  state;
  files: File[] = [];
  file: File;  // designer 등록 사진을 위한 file
  task: AngularFireUploadTask;
  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL;

  block: Block ={
    img : '',
    subtitle : [],
    title : [],
    desc: [],
    clickz : [],
    backwhite : true,
    fontBlack : true,
    link : '',
    ImgORVideo : true
  }
  constructor(
    private BlockService : BlockService,
    private storage: AngularFireStorage,
    private dialogRef : MatDialogRef<AddBlocksComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.state = this.data.state;
    console.log("[now state] : ", this.data);
  }

  // onSubmit(event) {
  //   if (this.data.isModify == true) {
  //     this.startUpload();
  //   } else if (this.data.isModify == false) {
  //     if (this.file != null) {
  //       this.startUpload();
  //     }
  //   }
  // }

  initBlock(){
    this.block.img = '';
    this.block.subtitle = [];
    this.block.title = [];
    this.block.desc = [];
    this.block.clickz = [];
    this.block.backwhite = true;
    this.block.fontBlack = true;
    this.block.link = '';
    this.block.ImgORVideo = true;
    if(this.data.isModify){
      this.data.modify_Blocks.img = '';
      this.data.modify_Blocks.subtitle = [];
      this.data.modify_Blocks.title = [];
      this.data.modify_Blocks.desc = [];
      this.data.modify_Blocks.clickz = [];
      this.data.modify_Blocks.backwhite = true;
      this.data.modify_Blocks.fontBlack = true;
      this.data.modify_Blocks.link = '';
      this.data.modify_Blocks.ImgORVideo = true;
    }
    this.files = [];
    this.file = null;
  }

  onRealDelete() {
    this.storage.storage.refFromURL(this.data.modify_Blocks.img).delete();
    this.BlockService.deleteBlock(this.data.modify_Blocks);
    this.dialogRef.close();
  }

  onDrop(files: FileList) {
    if (files.length == 1) {
      this.file = files[0];
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  isbackwhite(event) {
    if (event.checked) {
      this.block.backwhite = true;
    }
    else {
      this.block.backwhite = false;
    }

  }

  isfontBlack(event) {
    if (event.checked) {
      this.block.fontBlack = true;
    } else {
      this.block.fontBlack = false;
    }

  }

  isImgORVideo(event){
    if(event.checked){
      this.block.ImgORVideo = true;
    }else{
      this.block.ImgORVideo = false;
    }
  }

  async Add_Button() {
    this.Upload_Start();

  }


  async Modify_Button() {
    this.Upload_Modify();
  }

  Upload_Start() {
    return new Promise<void>((resolve, reject) => {
      const path = "block/" + this.block.img + "/"  + "_" + `${Date.now()}_${this.file.name}`;
      const ref = this.storage.ref(path);
      this.storage.upload(path, this.file).then(
        async () => {
          let value = await ref.getDownloadURL().toPromise();
          this.block.img = value;
          this.BlockService.addBlock(this.block);
          this.dialogRef.close();
          resolve();
        }
      )
    });
  }


  Upload_Modify(){
    if (this.data.isModify == true) {
      // url 기준으로 지우고 시작
      if (this.file != null) {
        this.storage.storage.refFromURL(this.data.modify_Blocks.img).delete();
        const path = `designer/${Date.now()}_${this.file.name}`; // 경로를 지정해줌.
        const ref = this.storage.ref(path);
        this.task = this.storage.upload(path, this.file); // upload 를 시작하며 task 에 등록해줌.
        this.percentage = this.task.percentageChanges();
        this.snapshot = this.task.snapshotChanges().pipe(
          tap(console.log),
          finalize(async () => {
            this.downloadURL = await ref.getDownloadURL().toPromise();
            this.data.modify_Blocks.img = this.downloadURL;
            //  수정하는 부분 들어가줘야함.
            this.BlockService.modifiyBlock(this.data.modify_Blocks);
            this.initBlock();
            if ((await this.task).bytesTransferred == (await this.task).totalBytes) {
              console.log((await this.task).bytesTransferred, "=", (await this.task).totalBytes, "now same bytes");
              this.dialogRef.close();
            }
          })
        )
      } else {
        console.log("same url img");
        this.BlockService.modifiyBlock(this.data.modify_Blocks);
        this.initBlock();
        this.dialogRef.close();
      }
    }
    else{
      // const path = `block/${Date.now()}_${this.file.name}`; // 경로를 지정해줌.
      // const ref = this.storage.ref(path);
      // this.task = this.storage.upload(path, this.file); // upload 를 시작하며 task 에 등록해줌.
      // console.log(this.task);
      // this.percentage = this.task.percentageChanges();
      // this.snapshot = this.task.snapshotChanges().pipe(
      //   tap(console.log),
      //   finalize(async () => {
      //     console.log('3');
      //     console.log(this.percentage);
      //     this.downloadURL = await ref.getDownloadURL().toPromise();
      //     this.block.img = this.downloadURL;
      //     console.log(this.block);
      //     this.BlockService.addBlock(this.block);
      //     this.initBlock();
      //     if ((await this.task).bytesTransferred == (await this.task).totalBytes) {
      //       console.log((await this.task).bytesTransferred, "=", (await this.task).totalBytes, "now same bytes");
      //       this.dialogRef.close();
      //     }
      //     //console.log("downloadURL : " + this.downloadURL);
      //   })
      // )

      // const path = `block/${Date.now()}_${this.file.name}`; // 경로를 지정해줌.
      // const ref = this.storage.ref(path);
      // this.task = this.storage.upload(path, this.file); // upload 를 시작하며 task 에 등록해줌.
      // this.percentage = this.task.percentageChanges();
      // this.snapshot = this.task.snapshotChanges().pipe(
      //   tap(console.log),
      //   finalize(async () => {
      //     console.log(this.percentage);
      //     this.downloadURL = await ref.getDownloadURL().toPromise();
      //     this.block.img = this.downloadURL;
      //     this.BlockService.addBlock(this.block);
      //     this.initBlock();
      //     if ((await this.task).bytesTransferred == (await this.task).totalBytes) {
      //       console.log((await this.task).bytesTransferred, "=", (await this.task).totalBytes, "now same bytes");
      //       this.dialogRef.close();
      //     }
      //     //console.log("downloadURL : " + this.downloadURL);
      //   })
      // )

      // console.log(this.snapshot);

    }
  }



}
