import { Directive } from "@angular/core";
import { ElementRef } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { Observable } from "rxjs";
import { OnDestroy } from "@angular/core";
import { OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { ScrollleftService } from '../services/scrollleft.service';


@Directive({
    selector: "[scrollPercentage]",
    outputs: [ "scrollPercentageEvent: scrollPercentage" ]
})
export class ScrollVDirective implements OnInit, OnDestroy {

  public scrollPercentageEvent: EventEmitter<number>;

  private elementRef: ElementRef;
  private elementScrollPercentage: ScrollleftService;
  private subscription: Subscription;

  constructor(
      elementRef: ElementRef,
      elementScrollPercentage: ScrollleftService
      ) {

      this.elementRef = elementRef;
      this.elementScrollPercentage = elementScrollPercentage;
      this.scrollPercentageEvent = new EventEmitter();
      this.subscription = null;

  }


  public ngOnDestroy() : void {
      ( this.subscription ) && this.subscription.unsubscribe();
  }


  public ngOnInit() : void {
    this.subscription = this.elementScrollPercentage
      .getScrollAsStream(this.elementRef.nativeElement)
      .subscribe(
        (percent: number): void => {
          this.scrollPercentageEvent.next(percent);

        }
      )
      ;

  }

}
