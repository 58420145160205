import { Injectable, NgModule } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestoreDocument, AngularFirestore } from '@angular/fire/firestore'
import { Product } from '../models/product';
import { Observable } from 'rxjs';
import { map, refCount } from 'rxjs/operators';
//import { ConsoleReporter } from 'jasmine';
@Injectable({
  providedIn: 'root',
})

export class ProductService {
  productCollection: AngularFirestoreCollection<Product>;
  products: Observable<Product[]>;
  productDoc: AngularFirestoreDocument;
  selectProduct: Product;

  // tmpMap: Map<string, Map<string, string>>;

  constructor(public afs: AngularFirestore) {

    this.productCollection = this.afs.collection('products',
      ref => ref.orderBy('name', 'asc'));
    //ref => ref.where('color', "array-contains", "grey"));


    this.products = this.productCollection
      .snapshotChanges()
      .pipe(map(docs => {
        return docs.map(doc => {
          const data = doc.payload.doc.data() as Product;
          data.id = doc.payload.doc.id;
          return data;
        })
      }))
  }

  getFilterProduct(a, b) {
    var filterdProducts;
    this.productCollection = this.afs.collection('products', ref => ref.orderBy("name", "asc").where(a, "==", b));
    filterdProducts = this.productCollection
      .snapshotChanges()
      .pipe(map(docs => {
        return docs.map(doc => {
          const data = doc.payload.doc.data() as Product;
          data.id = doc.payload.doc.id;
          return data;
        })
      }))
    return filterdProducts;
  }

  getProducts() {
    return this.products;
  }

  addProducts(product: Product) {
    this.productCollection.add(product);
  }

  deleteProduct(product: Product) {
    this.productDoc = this.afs.doc('products/' + product.id);
    this.productDoc.delete();
  }

  setSelectProduct(input) {
    this.selectProduct = input;
  }
  getSelectProduct() {
    return this.selectProduct;
  }

  admin_now_peek_product: Product; // admin page 에서 현재 픽하게 된 프로덕트를 담고 있는다.
  setAdminNowPeekProduct(input_) {
    this.admin_now_peek_product = input_;
    // console.log("Now Select Product : " + JSON.stringify(this.admin_now_peek_product));
  }

  getProductInfoByCollectionAndName(collection, name) {
    this.productCollection = this.afs.collection('products', ref => ref.where('collection', '==', collection).where('name', '==', name));
    //this.productCollection = this.afs.collection('products', ref => ref.orderBy("name","asc"));
    var product = this.productCollection
      .snapshotChanges()
      .pipe(map(docs => {
        return docs.map(doc => {
          const data = doc.payload.doc.data() as Product;
          data.id = doc.payload.doc.id;
          return data;
        })
      }))
    return product;
  }


  modifyProduct(product: Product) {
    this.productDoc = this.afs.doc('products/' + product.id);
    this.productDoc.update(product);
  }

}
