import { Component, OnInit } from '@angular/core';
import { Album } from 'src/app/models/album';
import { AlbumService } from 'src/app/services/album.service';

import { MatDialog } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
//import {MatPaginator} from '@angular/material/paginator';
import { AlbumAddComponent } from 'src/app/components/album/album-add/album-add.component';
import { AngularFireStorage } from '@angular/fire/storage';
import { first } from 'rxjs/operators';
import { element } from 'protractor';
import { DownLoadComponent } from 'src/app/components/down-load/down-load.component';
import { ReturnStatement } from '@angular/compiler';

// import { search } from 'core-js/fn/symbol';


@Component({
  selector: 'app-album-view',
  templateUrl: './album-view.component.html',
  styleUrls: ['./album-view.component.scss']
})
export class AlbumViewComponent implements OnInit {
  constructor(private albumService: AlbumService, private storage: AngularFireStorage, public dialog: MatDialog) { }

  albums: Album[];
  sendAlbum: Album;
  Clicked_download = false;
  selectT: string;
  test_value = true;


  tags = ['AGO', 'ALLEY', 'BALLOON', 'BELL', 'CIRKUS', 'MOZZI', 'NOVA', 'PINCH', 'PROBE', 'SHUTTLE'];
  tag_option01 = ['CATALOG', 'INFO', 'NEWS'];
  tag_option02 = ['IMAGE','PACKSHOT', 'PRODUCT INFO'];
  // search_values = [];
  search_values_op = [false, false, false];
  search_values_op2 = [false, false];
  keys = [];
  values = [];

  currentPage = 0;
  startpp = 1;
  NowPage = 1;
  pageSize = 8;
  numP = [];

  isSelectTags = [false, false, false, false, false, false, false, false, false];
  isOtherChecked = false;
  isSomethingChecked = false;

  selectAGO(event) {
    if (event.checked) {
      this.isOtherChecked = false;
      this.isSomethingChecked = true;
    } else {
      this.isSomethingChecked = false;
    }

    for (var i = 1; i < this.isSelectTags.length; i++) {
      this.isSelectTags[i] = false;
    }

    for (var i = 0; i < this.search_values_op2.length; i++) {
      this.search_values_op2[i] = false;
    }

    this.clicked();
  }

  selectOther(event) {
    if (event.checked) {
      this.isOtherChecked = true;
      this.isSomethingChecked = true;
    } else {
      this.isSomethingChecked = false;
      for(var i = 0 ; i < this.isSelectTags.length; i++){
        if(this.isSelectTags[i]==true){
          this.isSomethingChecked = true;
          break;
        }
      }
    }

    if (this.isSelectTags[0] == true) {
      this.isSelectTags[0] = false;
    }
    for (var i = 0; i < this.search_values_op.length; i++) {
      this.search_values_op[i] = false;
    }

    this.clicked();
  }

  selectOption() {
    this.clicked();
  }
  // @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  ngOnInit() {
    this.albumService.GetAlbums().subscribe(albums => {
      this.albums = albums;
      for (var i = 0; i < albums.length; i++) {
        var keyList = [];
        for (var m = 0; m < albums[i].files.length; m++) {
          keyList.push(Object.keys(albums[i].files[m]));
        }
        this.keys.push(keyList);
      }


      for (var i = 0; i < albums.length; i++) {
        var adresses = [];
        for (var j = 0; j < this.keys[i].length; j++) {
          adresses.push(Object.values(albums[i].files[j]));
        }
        this.values.push(adresses);
      }

      this.numberOfPages();
    })


    // for (var i = 0; i < this.tags.length; i++) {
    //   this.search_values.push(false);
    // }

    window.scrollTo(0, 0)
  }

  async clicked() {
    let tags_ = [];

    // if (this.selectT) {
    //   // console.log(this.selectT);
    //   tags_.push(this.selectT);
    // }

    for (var i = 0; i < this.tags.length; i++) {
      if (this.isSelectTags[i] == true) {
        tags_.push(this.tags[i]);
      }
    }


    // if (this.isSelectTags[0] == true) {
    //   for (var i = 0; i < this.search_values_op.length; i++) {
    //     if (this.search_values_op[i] == true) {
    //       tags_.push(this.tag_option01[i]);
    //     }
    //   }
    // } else {
    //   for (var i = 0; i < this.search_values_op2.length; i++) {
    //     if (this.search_values_op2[i] == true) {
    //       tags_.push(this.tag_option02[i]);
    //     }
    //   }
    // }



    if (tags_.length == 0) {
      this.albumService.GetAlbums().subscribe(albums => {
        this.albums = albums;
      })
    } else {
      let list_ = [];
      await this.albumService.FindAlbum(tags_).then(snaphost => {
        snaphost.forEach(doc => {
          let tmp = doc.data() as Album;
          list_.push(tmp);
        })
      });



      // list_.forEach(element => {
      //   var isAllFind = true;
      //   for (var i = 0; i < tags_.length; i++) {
      //     var isMatched = false;
      //     for (var m = 0; m < element.tag.length; m++) {
      //       if (tags_[i] == element.tag[m]) {
      //         isMatched = true;
      //         break;
      //       }
      //     }
      //     if (isMatched) {
      //       continue;
      //     } else {
      //       isAllFind = false;
      //       break;
      //     }
      //   }
      //   if (isAllFind) {
      //     finalList.push(element);
      //   }
      // });

      var categoryList = [];
      list_.forEach(element => {
        for (var i = 0; i < tags_.length; i++) {
          var isMatched = false;
          for (var m = 0; m < element.tag.length; m++) {
            if (tags_[i] == element.tag[m]) {
              isMatched = true;
              break;
            }
          }
          if (isMatched) {
            categoryList.push(element);
          }
        }
      });


      var finalList = [];
      if (this.isSelectTags[0] == true) {
        var isCheckedOption = false;
        for (var i = 0; i < this.search_values_op.length; i++) {
          if (this.search_values_op[i] == true) {
            isCheckedOption = true;
            break;
          }
        }
        if (isCheckedOption) {
          categoryList.forEach(element => {
            for (var i = 0; i < this.search_values_op.length; i++) {
              if (this.search_values_op[i]){
                for (var m = 0; m < element.tag.length; m++) {
                  if (this.tag_option01[i] == element.tag[m]) {
                    finalList.push(element);
                    break;
                  }
                }
              }

            }
          });
        } else {
          finalList = categoryList;
        }
      } else {
        var isCheckedOption = false;
        // console.log("this.search_values_op2" , this.search_values_op2);
        for (var i = 0; i < this.search_values_op2.length; i++) {
          if (this.search_values_op2[i] == true) {
            isCheckedOption = true;
            break;
          }
        }
        if (isCheckedOption) {
          categoryList.forEach(element => {
            for (var i = 0; i < this.search_values_op2.length; i++) {
              if (this.search_values_op2[i]){
                for (var m = 0; m < element.tag.length; m++) {
                  if (this.tag_option02[i] == element.tag[m]) {
                    finalList.push(element);
                    break;
                  }
                }
              }

            }
          });
        } else {
          finalList = categoryList;
        }
      }

      // console.log("fianal list : ", finalList);






      this.albums = finalList;
    }

    this.keys = [];
    this.values = [];

    for (var i = 0; i < this.albums.length; i++) {
      var keyList = [];
      for (var m = 0; m < this.albums[i].files.length; m++) {
        keyList.push(Object.keys(this.albums[i].files[m]));
      }
      this.keys.push(keyList);
    }



    for (var i = 0; i < this.albums.length; i++) {
      var adresses = [];
      for (var j = 0; j < this.keys[i].length; j++) {
        adresses.push(Object.values(this.albums[i].files[j]));
      }
      this.values.push(adresses);
    }

    this.numberOfPages();
    //window.scrollTo(0, 0);
  }



  numberOfPages() {
    window.scrollTo(0, 0);
    this.numP = [];
    var newP = Math.ceil(this.albums.length / this.pageSize);
    if (newP > 0) {
      for (var i = 0; i < newP; i++) {
        var pnum = i + 1;
        this.numP.push(pnum);
      }
      this.NowPage = 1;
    } else {
      this.NowPage = 1;
      this.numP.push(1);
    }
  }

  pageJump(my: number) {
    this.NowPage = my;
    window.scrollTo(0, 0);
    //console.log("makeapage", this.NowPage );
  }

  downloadUrl(url: string) {
    // console.log("works");
    let a: any = document.createElement('a');
    a.href = url;
    document.body.appendChild(a);
    a.style = 'display: none';
    a.click();
    a.remove();
  };


  OpenAlbum(event, Album) {
    //console.log(Album);
    this.Clicked_download = true;
    this.sendAlbum = Album;
    // this.keys = Keys;
    // this.values = Values;

    const dialogRef = this.dialog.open(DownLoadComponent, {
      width: '30%',
      minWidth: '350px',
      data: { Clicked_download: this.Clicked_download, sendAlbum: this.sendAlbum }

    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      this.sendAlbum = result;
      this.Clicked_download = false;
    });

  }
}
