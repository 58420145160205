import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestoreDocument, AngularFirestore } from '@angular/fire/firestore';
import { Collection } from '../models/collection';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CollectionService {
  collectiveCollection: AngularFirestoreCollection<Collection>;
  collections: Observable<Collection[]>
  collectivDoc: AngularFirestoreDocument;
  selectCollection: Collection;

  constructor(public afs: AngularFirestore) {
    this.collectiveCollection = this.afs.collection('collections',
     ref => ref.orderBy('name', 'asc'));
    
     this.collections = this.collectiveCollection
      .snapshotChanges()
      .pipe(map(docs => {
        return docs.map(doc => {
          const data = doc.payload.doc.data() as Collection;
          data.id = doc.payload.doc.id;
          return data;
        })
      }))
    //console.log("collections : ", this.Collections);
  }
  getCollections() {
    return this.collections;
  }

  addCollection(collection: Collection) {
    this.collectiveCollection.add(collection);
  }
  deleteCollection(collection: Collection) {
    this.collectivDoc = this.afs.doc('collections/' + collection.id);
    this.collectivDoc.delete();
  }
  modifyCollection(collection: Collection) {
    this.collectivDoc = this.afs.doc('collections/' + collection.id);
    this.collectivDoc.update(collection);
  }


  setSelectCollection(input) {
    this.selectCollection = input;
  }
  getSelectCollection() {
    return this.selectCollection;
  }
}
