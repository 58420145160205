import { Component, OnInit } from '@angular/core';
import { Block } from 'src/app/models/block';
import { BlockService } from 'src/app/services/block.service';
import { MatDialog } from '@angular/material/dialog';
import { AddBlocksComponent } from 'src/app/components/blocks/add-blocks/add-blocks.component';
import { first  } from 'rxjs/operators';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-blocks-manager',
  templateUrl: './blocks-manager.component.html',
  styleUrls: ['./blocks-manager.component.css']
})
export class BlocksManagerComponent implements OnInit {

  constructor(public authService: AuthService, private BlockService : BlockService, public dialog : MatDialog) { }

  state = 0;
  blocks : Block[];
  isModify = false;
  del = false;
  modify_Blocks : Block;
  block : Block;
  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.BlockService.getBlocks().pipe(first()).subscribe(blocks => {
      this.blocks = blocks;
    })
    this.isModify = false;
  }

  logoutUser() {
    this.authService.logout()
      .then(res => {
        console.log(res);
        console.log('로그아웃 합니다.');
      }, err => {
        window.alert(err.message);
      });
  }

  deleteBlock(event, block){
    this.state = 2;
    this.del = true;
    this.modify_Blocks = block;
    //console.log(this.modify_Blocks);
    const dialogRef = this.dialog.open(AddBlocksComponent, {
      width : '1050px',
      data : {del : this.del, isModify : this.isModify, modify_Blocks : this.modify_Blocks, state: this.state}
    })
  }

  modifyBlock(event, block){
    this.state = 1;
    this.del = false;
    this.isModify =true;
    this.modify_Blocks = block;
    console.log(this.modify_Blocks);
    const dialogRef = this.dialog.open(AddBlocksComponent, {
      width : '1050px',
      data : {del : this.del, isModify : this.isModify, modify_Blocks : this.modify_Blocks, state: this.state}
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The Modify dialog was closed');
      this.block = result;
    });
  }

  onAdd(event) {
    this.del = false;
    this.state = 0;
    this.isModify = false;
    const dialogRef = this.dialog.open(AddBlocksComponent, {
      width: '1050px',
      data: {del : this.del, isModify: this.isModify ,state: this.state}
    });
  }

}
