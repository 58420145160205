import { Component, OnInit, Input, Inject } from '@angular/core';
import { Collection } from 'src/app/models/collection';
import { CollectionService } from 'src/app/services/collection.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage'
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-collection',
  templateUrl: './add-collection.component.html',
  styleUrls: ['./add-collection.component.scss']
})
export class AddCollectionComponent implements OnInit {

  // @Input() modify_Collection: Collection; // 수정될 디자이너의 정보
  // @Input() isModify: boolean; // 현재 수정 버전인가에 대한 flag

  files_img: File[] = [];
  files_thumb: File[] = [];

  task: AngularFireUploadTask;
  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL;
  isChecked = true;

  collection: Collection = {
    name: '',
    designer: [],
    year: '',
    img: [],
    thumb_img: [],
    blackimg: true,
    mainImgisVertical: true,
    description: [],
    text: [],
  }

  constructor(
    private collectionService: CollectionService,
    private storage: AngularFireStorage,
    private dialogRef : MatDialogRef<AddCollectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    if (this.data.isModify == true || this.data.isDelete == true) {
      this.copyValue(this.data.modify_Collection);
      console.log(this.data.modify_Collection);
    }

    
  }

  copyValue(input_) {
    this.collection = JSON.parse(JSON.stringify(input_));
  }

  async main() {

    for (var i = 0; i < this.files_img.length; i++) {
      await this.fileUpload('img', this.files_img[i]);
    }
    for (var i = 0; i < this.files_thumb.length; i++) {
      await this.fileUpload('thumb', this.files_thumb[i]);
    }
    this.collectionService.addCollection(this.collection);

    this.dialogRef.close();
  }

  isVerticalChange(event) {
    if (event.checked) {
      this.collection.mainImgisVertical = true;
    }
    else{
      this.collection.mainImgisVertical = false;
    }
  }

  isBlackImgChange(event) {
    if(event.checked){
      this.collection.blackimg = true;
    }
    else{
      this.collection.blackimg = false;
    }
  }

  // submit button 을 눌렀을 경우 실행 되는 부분
  onSubmit() {
    if (this.data.isModify == true) {
      this.modify();
    } else {
      this.main();
    }
  }

  initCollection() {
    this.collection.name = '';
    this.collection.designer = [];
    this.collection.year = '';
    this.collection.img = [];
    this.collection.thumb_img = [];
    this.collection.blackimg = true;
    this.collection.mainImgisVertical = true;
    this.collection.description = [];
    this.collection.text = [];
  }

  onDrop(files: FileList) {

  }

  onDrop_img(files: FileList) {
    for (var i = 0; i < files.length; i++) {
      this.files_img.push(files[i]);
    }
  }

  onDrop_thumb(files: FileList) {
    for (var i = 0; i < files.length; i++) {
      this.files_thumb.push(files[i]);
    }
  }

  fileUpload(tag, file) {
    return new Promise<void>((resolve, reject) => {
      var path = '';
      if (tag == 'img') {
        path = `collection_img/${Date.now()}_${file.name}`;
      } else if (tag == 'thumb') {
        path = `collection_thumb/${Date.now()}_${file.name}`;
      }
      var ref = this.storage.ref(path);
      this.task = this.storage.upload(path, file);
      this.snapshot = this.task.snapshotChanges().pipe(
        tap(console.log),
        finalize(async () => {
          var url = await ref.getDownloadURL().toPromise();
          if (tag == 'img') {
            this.collection.img.push(url);
          } else if (tag == 'thumb') {
            this.collection.thumb_img.push(url);
          }
          resolve();
        })
      )
    })
  }

  onRealDelete() {
    for (var i = 0; i < this.collection.img.length; i++) {
      this.storage.storage.refFromURL(this.collection.img[i]).delete();
    }

    for (var i = 0; i < this.collection.thumb_img.length; i++) {
      this.storage.storage.refFromURL(this.collection.thumb_img[i]).delete();
    }

    this.collectionService.deleteCollection(this.collection);

    this.dialogRef.close();
  }

  onCancel() {
    this.dialogRef.close();
  }

  delete_img(event, index_) {
    this.collection.img.splice(index_, 1);
  }

  delete_thumb(event, index_){
    this.collection.thumb_img.splice(index_, 1);
  }

  // 기존 배열과 비교해서 없어진것은 삭제해주고 새로운것은 등록해준다.
  async modify() {
    // 기본의 데이터와 비교 삭제
    for (var i = 0; i < this.data.modify_Collection.img.length; i++) {
      let isFound = false;
      for (var m = 0; m < this.collection.img.length; m++) {
        if (this.data.modify_Collection.img[i] == this.collection.img[m]) {
          isFound = true;
          break;
        }
      }
      // 만약 찾았다면 넘어감
      if (isFound == true) {
        continue;
      }
      // 찾지 못했다면 삭제!
      else {
        await this.storage.storage.refFromURL(this.data.modify_Collection.img[i]).delete();
      }
    }

    for (var i = 0; i < this.files_img.length; i++) {
      await this.fileUpload('img', this.files_img[i]);
    }
    for (var i = 0; i < this.files_thumb.length; i++) {
      await this.fileUpload('thumb', this.files_thumb[i]);
    }

    this.collectionService.modifyCollection(this.collection);
    this.dialogRef.close();
  }
}
