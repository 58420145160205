import { Component, OnInit } from '@angular/core';
import { Product } from '../../../models/product';
import { ProductService } from 'src/app/services/product.service';
import { MatDialog } from '@angular/material/dialog';
import { AddProductComponent } from '../add-product/add-product.component';
import 'rxjs/add/operator/map';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-product-manager',
  templateUrl: './product-manager.component.html',
  styleUrls: ['./product-manager.component.scss']
})
export class ProductManagerComponent implements OnInit {
  products: Product[];
  now_admin_peek_product: Product;


  currentPage = 0;
  startpp = 1;
  NowPage = 1;
  pageSize = 10;
  numP = [];


  constructor(public authService: AuthService, private productService: ProductService,  public dialog: MatDialog) { }

  ngOnInit() {
    /** pipe 를 사용하게 되면 실시간 업데이트가 되지 않는다. */
    // this.productService.getProducts().pipe(first()).subscribe(products => {
    //   console.log("improduct 1");
    //   this.products = products;
    // })

    this.productService.getProducts().subscribe(products => {
      this.products = products;
      this.numberOfPages();
    })

  }

  logoutUser() {
    this.authService.logout()
      .then(res => {
        console.log(res);
        console.log('로그아웃 합니다.');
      }, err => {
        window.alert(err.message);
      });
  }


  deleteProduct(event, product) {
    console.log("Clicked Product : " , product);
    // this.productService.deleteProduct(product);

    this.isModify = false;
    this.isDelete = true;
    this.modify_Product = product;
    const dialogRef = this.dialog.open(AddProductComponent, {
      width: '1050px',
      data: { isDelete: this.isDelete, isModify: this.isModify, modify_Product: this.modify_Product }

    });
  }


  isModify = false;
  isDelete = false;
  modify_Product : Product;

  modifyProduct(event, product) {
    this.isModify = true;
    this.isDelete = false;
    this.modify_Product = product;
    const dialogRef = this.dialog.open(AddProductComponent, {
      width: '1050px',
      data: { isDelete: this.isDelete, isModify: this.isModify, modify_Product: this.modify_Product }

    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.modify_Product = result;
    });
  }

  onAdd(event) {
    this.isModify = false;
    this.isDelete = false;
    const dialogRef = this.dialog.open(AddProductComponent, {
      width: '1050px',
      data: { isDelete: this.isDelete, isModify: this.isModify }
    });

  }


  numberOfPages() {
    window.scrollTo(0, 0);
    this.numP =[];
    var newP = Math.ceil(this.products.length / this.pageSize);
    if (newP>0){
      for(var i=0; i<newP; i++){
        var pnum = i+1;
        this.numP.push(pnum);
      }
      this.NowPage = 1;
    }else{
      this.NowPage = 1;
      this.numP.push(1);
    }
  }

  pageJump(my : number){
    this.NowPage =  my;
    window.scrollTo(0, 0);
    //console.log("makeapage", this.NowPage );
  }

}
