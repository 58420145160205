import { Component, OnInit, Inject } from '@angular/core';
import { Album } from 'src/app/models/album';
import { AlbumService } from 'src/app/services/album.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

var fileSaver = require('file-saver');


@Component({
  selector: 'app-down-load',
  templateUrl: './down-load.component.html',
  styleUrls: ['./down-load.component.css']
})
export class DownLoadComponent implements OnInit {

  Album: Album = {
    name: '',
    thumbnail: '',
    files: []
  }
  Keys = [];
  Values = [];
  httpsReference: Observable<string | null>;
  constructor(private AlbumService: AlbumService,
    private storage: AngularFireStorage,
    private dialogRef: MatDialogRef<DownLoadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    if (this.data.Clicked_download == true) {
      this.copyValue(this.data.sendAlbum);
    }

    for (var m = 0; m < this.Album.files.length; m++) {
      if (JSON.stringify(Object.keys(this.Album.files[m])) === "IMAGE") {
      }
      // console.log(JSON.stringify(Object.keys(this.Album.files[m])).toString);
      this.Keys.push(Object.keys(this.Album.files[m]));
    }

    for (var j = 0; j < this.Keys.length; j++) {

      this.Values.push(Object.values(this.Album.files[j]));
    }
  }
  copyValue(input_) {
    this.Album = JSON.parse(JSON.stringify(input_));
  }

  download_button_click(event, m, key) {
    var album = this.Album;
    var xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = function (event) {
      var blob = xhr.response;

      // console.log("key : " + key);
      // console.log("3ds : " + key.indexOf(key.indexOf("3DS")));
      // console.log("3ds : " + key.toString().includes("3DS"));
      // console.log("dwg : " + key.toString().includes("D"));
      // console.log("dwg : " + key.indexOf(key.indexOf("3DS")));

      if(key.toString().indexOf("3DS") != -1){
        // console.log("1");
        fileSaver.saveAs(blob, album.name + "_" + key + ".3ds");
      }
      else if(key.toString().indexOf("DWG") != -1){
        // console.log("2");
        fileSaver.saveAs(blob, album.name + "_" + key + ".dwg");
      }
      else {
        // console.log("3");
        fileSaver.saveAs(blob, album.name + "_" + key);
      }
      
    

      // var url = window.URL.createObjectURL(blob);
      // window.open(url);
      // const file = new File([blob], 'image.png',
      // fileURLToPath)
    };
    xhr.open('GET', album.files[m][key]);
    xhr.send();



  }



  clickz(want) {
    const ref = this.storage.ref(want);
    // this.httpsReference = ref.getDownloadURL();
  }

  // clickz(want){
  //   this.httpsReference = this.storage.ref(want);
  //this.httpsReference = ref.getDownloadURL();
  //const link = document.createElement('a');
  //console.log( "url is : ",  this.httpsReference);
  // link.setAttribute('target', '_blank');
  // link.setAttribute('href', this.httpsReference);
  // document.body.appendChild(link);
  // link.click();
  // link.remove();


  // const link = document.createElement('a');
  // link.setAttribute('target', '_blank');
  // link.setAttribute('href', this.httpsReference);
  // document.body.appendChild(link);
  // link.click();
  // link.remove();

  // }
}
