import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestoreDocument, AngularFirestore } from '@angular/fire/firestore';
import { Block } from '../models/block';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BlockService {

  BlocksCollection: AngularFirestoreCollection<Block>;
  Blocks: Observable<Block[]>;
  BlockDoc: AngularFirestoreDocument;

  constructor(public afs: AngularFirestore) {
    this.BlocksCollection = this.afs.collection('blocks', ref => ref.orderBy('title', 'asc'));
    this.Blocks = this.BlocksCollection
      .snapshotChanges()
      .pipe(map(docs => {
        return docs.map(doc => {
          const data = doc.payload.doc.data() as Block;
          data.id = doc.payload.doc.id;
          return data;
        })
      }))
  }

  getBlocks(){
    return this.Blocks;
  }

  addBlock(Blocks : Block){
    this.BlocksCollection.add(Blocks);
  }

  modifiyBlock(Block : Block){
    this.BlockDoc = this.afs.doc('blocks/' + Block.id);
    this.BlockDoc.update(Block);
  }

  deleteBlock(Blocks : Block){
    console.log(Blocks);
    this.BlockDoc = this.afs.doc('blocks/'+ Blocks.id);
    this.BlockDoc.delete();
  }
}

