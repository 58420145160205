import { Component, OnInit, Input, Inject } from '@angular/core';
import { Product } from 'src/app/models/product';
import { ProductService } from 'src/app/services/product.service';
import { AngularFireUploadTask, AngularFireStorage } from '@angular/fire/storage';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})

export class AddProductComponent implements OnInit {
  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL;

  // isConfirm: boolean = false; // confirm 버튼을 눌렀는지 안눌렀는지
  file_path: string = "";  // 파일을 저장할 path

  task: AngularFireUploadTask; // upload 를 위한 task

  colorMap: Map<string, Map<string, string>>;

  before_peek_product: Product;
  now_peek_product: Product;

  product: Product = {
    id: '',
    //bulb_included: false,

    name: '',
    collection: '',
    designer: '',

    thumb_img: '',
    img: [],
    illu_img: '',
    download: '',

    model: '',
    dimensions: '',
    environment: '',

    cord_length: '',
    light_source: '',

    material: [],
    mounting: [],
    colors: [],

    ar_url : '',
    weight : '',
    download_link : '',

    //lumen_w: [],
    //lumen: [],
    //watt: [],
    //voltage: '',
    //mat_watt: [],
    //light_source_life_expectancy: '',
    //packaging_diensions: '',
    //packaging_weight: '',
    //light_control: '',
    //ip: '',
    //energy_class: '',
    //cri: '',
    //color_temperature: '',
    //class: '',
  }

  thumbnail_files: File[];
  thumbnail_urls: string[];

  image_files: File[];
  image_urls: string[];

  illu_files: File[];
  illu_urls: string[];

  download_files: File[];
  download_urls: string[];

  color_files: File[];
  color_files_index: Array<number>;
  color_urls: string[];

  constructor(
    public authService: AuthService,
    private productService: ProductService,
    private storage: AngularFireStorage,
    // private db: AngularFirestore,
    private dialogRef: MatDialogRef<AddProductComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  init() {
    // this.isConfirm = false;
    this.thumbnail_files = [];
    this.image_files = [];
    this.illu_files = [];
    this.download_files = [];
    this.color_files = [];
    this.color_files_index = [];

    this.thumbnail_urls = [];
    this.image_urls = [];
    this.illu_urls = [];
    this.download_urls = [];
    this.color_urls = [];

    this.product.model = '';
    this.product.dimensions = '';
    this.product.environment = '';

    this.product.cord_length = '';
    this.product.light_source = '';

    this.product.material = [];
    this.product.mounting = [];

    this.product.colors = [];

    this.product.ar_url = '';
    this.product.weight = '';
    this.product.download_link = '';

    // this.product.material.push("");
    // this.product.mounting.push("");
    // var tmpMap = new Map<String, String>();
    // tmpMap.set("color","");
    // //tmpMap.set("off","");
    // tmpMap.set("on","");
    // tmpMap.set("rgba","");
    // this.product.colors.push(tmpMap);
  }

  ngOnInit() {
    this.init();

    if (this.data.isModify == true || this.data.isDelete == true) {
      console.log("product is : " , this.data.modify_Product);
      this.copyValue(this.data.modify_Product);
    }

    //console.log(this.product);
  }

  copyValue(input_) {
    var parsed_data = JSON.parse(JSON.stringify(input_));
    this.product = parsed_data;
    console.log(this.product);
    parsed_data.material.forEach(element => {
      this.temp_Material.push({value:element});
    });
    parsed_data.mounting.forEach(element => {
      this.temp_Mounting.push({value:element});
    });
    this.product.material = [];
    this.product.mounting = [];
  }

  /**
   *  thumb_img: '',
    img: [],
    illu_img: '',
    download:'',
   */

  onSubmit() {
    if (this.data.isModify == true) {
      //console.log('product : ', this.product);
      this.modify();
    } else {
      //console.log('product : ', this.product);
      this.add();
    }
  }

  // 컨펌 버튼
  confirm() {
    if (this.product.name != '' && this.product.collection != '' && this.product.designer != '') {
      this.file_path = this.product.name + "_" + this.product.collection + "_" + this.product.designer;
      // this.isConfirm = !this.isConfirm;
    }
  }

  // 캔슬 버튼
  cancel() {
    this.init();
    this.product.model = '';
    ////console.log("files : " , this.thumbnail_files);
    //this.isConfirm = !this.isConfirm;
  }

  // 파일이 들어 왔을 경우 실행될 함수.
  onDrop(files: FileList, tag: string, index = 0) {
    switch (tag) {
      case "product_thumbnail":
        for (let i = 0; i < files.length; i++) {
          this.thumbnail_files.push(files.item(i));
        }
        break;

      case "product_image":
        for (let i = 0; i < files.length; i++) {
          this.image_files.push(files.item(i));
        }
        break;

      case "product_illu":
        for (let i = 0; i < files.length; i++) {
          this.illu_files.push(files.item(i));
        }
        break;

      case "download_file":
        for (let i = 0; i < files.length; i++) {
          this.download_files.push(files.item(i));
        }
        break;

      case "product_color":
        for (let i = 0; i < files.length; i++) {
          this.color_files.push(files.item(i));
          console.log("index is : ", index);
          this.color_files_index.push(index);
        }
        break;
    }
  }

  addThumbnailUrls(url) {
    //console.log("hu");
    this.thumbnail_urls.push(url);
  }

  addImageUrls(url) {
    this.image_urls.push(url);
  }

  addIlluUrls(url) {
    this.illu_urls.push(url);
  }

  addDownloadUrls(url) {
    this.download_urls.push(url);
  }

  addColorUrls(url) {
    this.color_urls.push(url);
  }

  //#region color
  temp_color_string: string = "";

  focusout_color(data_, index) {
    //this.product.colors[index].set("color", data_);
    //console.log("Hi color : ", data_);
    // //console.log("product colors : " , this.product.colors[index].get('color'));
    //this.product.color[index] = data_;
  }

  focusout_rgba(data_, index) {
    // this.product.colors[index].set('rgba', data_);
  }

  addColor() {
    let color = {
      "color": "",
      "off": "",
      "on": "",
      "rgba": ""
    };
    this.product.colors.push(color);
  }

  removeColor(index) {
    this.product.colors.splice(index, 1);
  }

  checkcolor() {
    //console.log(this.product.colors);
  }
  //#endregion

  //#region #material
  temp_Material = []; // Temp 를 만들어 줘서 Obejct 로 변환하게 만들어준다.

  // focusout_mat(data_, index) {
  //   this.product.material[index] = data_;
  // }

  addMaterial() {
    // this.product.material.push("");
    this.temp_Material.push({value:''});
  }

  removeMaterial(index) {
    //console.log(index);
    // this.product.material.splice(index, 1);
    this.temp_Material.splice(index, 1);
  }

  async applyTempMaterial(){
    for(var i = 0 ; i < this.temp_Material.length; i++){
      await this.product.material.push(this.temp_Material[i].value);
    }
    return;
  }

  //#endregion

  //#region #mounting
  temp_Mounting = [];

  addMounting() {
    // this.product.mounting.push("");
    this.temp_Mounting.push({value:''});
    console.log(this.temp_Mounting);
  }

  removeMounting(index) {
    // this.product.mounting.splice(index, 1);
    this.temp_Mounting.splice(index, 1);
  }

  async applyTempMounting(){
    for(var i = 0 ; i < this.temp_Mounting.length; i++){
      await this.product.mounting.push(this.temp_Mounting[i].value);
    }
    return;
  }
  //#endregion

  async onRealDelete() {
    //console.log("error?");
    if(this.product.thumb_img!="") this.storage.storage.refFromURL(this.product.thumb_img).delete().catch(err=>{
      //console.log("hi error : " , err);
    });

    //console.log("error?");
    for (var i = 0; i < this.product.img.length; i++) {
      this.storage.storage.refFromURL(this.product.img[i]).delete();
    }

    //console.log("error?");
    if(this.product.illu_img!="") this.storage.storage.refFromURL(this.product.illu_img).delete();
    //console.log("error?");
    if(this.product.download!="") this.storage.storage.refFromURL(this.product.download).delete();

    for (var i = 0; i < this.product.colors.length; i++) {
      let url = this.product.colors[i]["on"];
      //console.log("error?");
      this.storage.storage.refFromURL(url).delete();
      // await this.storage.storage.refFromURL(this.product.colors[i]["off"]).delete();
    }

    await this.productService.deleteProduct(this.product);

    this.dialogRef.close();
  }

  onCancel() {
    this.dialogRef.close();
  }

  startUpload(tag, file, index = 0) {
    return new Promise<void>((resolve, reject) => {
      var path = "";
      this.file_path = this.product.name + "_" + this.product.collection + "_" + this.product.designer;
      path = this.file_path + "/" + `${Date.now()}_${file.name}`;
      const ref = this.storage.ref(path);
      this.task = this.storage.upload(path, file);
      this.snapshot = this.task.snapshotChanges().pipe(
        tap(console.log),
        finalize(async () => {
          let url = await ref.getDownloadURL().toPromise();
          switch (tag) {
            case "product_thumbnail":
              this.product.thumb_img = url;
              break;

            case "product_image":
              this.product.img.push(url);
              break;

            case "product_illu":
              this.product.illu_img = url;
              break;

            case "download_file":
              this.product.download = url;
              break;

            case "product_color":
              this.product.colors[index]["on"] = url;
              this.product.colors[index]["off"] = url;
              break;
          }
          //console.log("url : ", url);
          resolve();
        })
      );
    })
  }

  async add() {
    await this.applyTempMaterial();
    await this.applyTempMounting();

    for (var i = 0; i < this.thumbnail_files.length; i++) {
      await this.startUpload('product_thumbnail', this.thumbnail_files[i]);
    }

    for (var i = 0; i < this.image_files.length; i++) {
      await this.startUpload('product_image', this.image_files[i]);
    }

    for (var i = 0; i < this.illu_files.length; i++) {
      await this.startUpload('product_illu', this.illu_files[i]);
    }

    for (var i = 0; i < this.download_files.length; i++) {
      await this.startUpload('download_file', this.download_files[i]);
    }

    for (var i = 0; i < this.color_files.length; i++) {
      await this.startUpload('product_color', this.color_files[i], i);
    }
    this.productService.addProducts(this.product);
    //console.log('product : ' , this.product);
    this.dialogRef.close();
  }

  async modify() {

    await this.applyTempMaterial();
    await this.applyTempMounting();

    // thumb_img 수정에 대한 부분
    if(this.product.thumb_img == "" && this.data.modify_Product.thumb_img != "") {
      //console.log("error?");
      this.storage.storage.refFromURL(this.data.modify_Product.thumb_img).delete();

      for (var i = 0; i < this.thumbnail_files.length; i++) {
        await this.startUpload('product_thumbnail', this.thumbnail_files[i]);
      }
    }

    // images
    if(this.product.img.length == 0 && this.data.modify_Product.img.length != 0) {
      //console.log("error?");
      for(var i = 0; i < this.data.modify_Product.img.length; i++){
        this.storage.storage.refFromURL(this.data.modify_Product.img[i]).delete();
      }

      for (var i = 0; i < this.image_files.length; i++) {
        await this.startUpload('product_image', this.image_files[i]);
      }
    }

    // illu_files 수정에 대한 부분
    if(this.product.illu_img == "" && this.data.modify_Product.illu_img != "") {
      //console.log("error?");
      this.storage.storage.refFromURL(this.data.modify_Product.illu_img).delete();

      for (var i = 0; i < this.illu_files.length; i++) {
        await this.startUpload('product_illu', this.illu_files[i]);
      }
    }

    // download 수정에 대한 부분

    if(this.product.download == "" && this.data.modify_Product.download != "") {
      //console.log("error?");
      this.storage.storage.refFromURL(this.data.modify_Product.download).delete();

      for (var i = 0; i < this.download_files.length; i++) {
        await this.startUpload('download_file', this.download_files[i]);
      }
    }


    for (var i = 0; i < this.data.modify_Product.colors.length; i++) {
      let isFound = false;
      for (var m = 0; m < this.product.colors.length; m++) {
        if (this.data.modify_Product.colors[i]["on"] == this.product.colors[m]["on"]) {
          isFound = true;
          break;
        }
      }
      // 만약 찾았다면 넘어감
      if (isFound == true) {
        continue;
      }
      // 찾지 못했다면 삭제!
      else {
        //console.log("error?");
        this.storage.storage.refFromURL(this.data.modify_Product.colors[i]["on"]).delete();
      }
    }

    for (var i = 0; i < this.color_files.length; i++) {
      await this.startUpload('product_color', this.color_files[i], this.color_files_index[i]);
    }

    await this.productService.modifyProduct(this.product);

    this.dialogRef.close();
  }

  delete_thumbnail(){
    this.product.thumb_img = '';
  }
  delete_illu(){
    this.product.illu_img = '';
  }
  delete_download(){
    this.product.download = '';
    //console.log(this.product);
  }
  delete_img(event, index){
    // this.product.img.splice(index, 1);
    this.product.img = [];
  }
  delete_color(event, input_){
    for( var i = 0 ; i < this.product.colors.length; i++){
      if(this.product.colors[i]["on"] == input_){
        this.product.colors[i]["on"] = "";
        this.product.colors[i]["off"] = "";
      }
    }
  }

  onCheck(){
    console.log(this.product);
    console.log("0000000000000");
    console.log(this.data.modify_Product);
    // console.log(this.color_files);
    // console.log(this.color_files_index);
  }

}
