import { Component, OnInit } from '@angular/core';
import { Album } from 'src/app/models/album';
import { AlbumService } from 'src/app/services/album.service';

import { MatDialog } from '@angular/material/dialog';
import { AlbumAddComponent } from '../album-add/album-add.component';
import { AngularFireStorage } from '@angular/fire/storage';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-album-manager',
  templateUrl: './album-manager.component.html',
  styleUrls: ['./album-manager.component.scss']
})
export class AlbumManagerComponent implements OnInit {

  constructor(public authService: AuthService,private alubmService: AlbumService, private storage: AngularFireStorage, public dialog: MatDialog) { }

  // 0. ADD, 1. MODIFY, 2.DELETE
  state = 0;

  albums: Album[];
  keys = [];
  values = [];

  currentPage = 0;
  startpp = 1;
  NowPage = 1;
  pageSize = 12;
  numP = [];

  ngOnInit() {
    //console.log("album-manager start!");
    this.alubmService.GetAlbums().subscribe(albums => {
      this.albums = albums;
      this.keys = [];
      this.values = [];
      for( var i = 0 ; i < albums.length; i ++ ){
        var keyList = [];
        for(var m = 0; m < albums[i].files.length; m++){
          keyList.push(Object.keys(albums[i].files[m]));
        }
        this.keys.push(keyList);
      }



      for( var i = 0 ; i < albums.length; i ++ ){
        var adresses = [];
        for(var j = 0 ; j < this.keys[i].length; j++ ){
          adresses.push(Object.values(albums[i].files[j]));
        }
        this.values.push(adresses);
      }
      this.numberOfPages();
    });

    window.scrollTo(0, 0);
  }

  logoutUser() {
    this.authService.logout()
      .then(res => {
        console.log(res);
        console.log('로그아웃 합니다.');
      }, err => {
        window.alert(err.message);
      });
  }

  onAdd(event) {
    this.state = 0;
    const dialogRef = this.dialog.open(AlbumAddComponent, {
      width: '1050px',
      data: { state: this.state }
    });
  }

  downloadButton(url) {
    // window.open(url);
    window.location.assign(url);
  }

  modifyAlbum(event, album) {
    this.state = 1;
    const dialogRef = this.dialog.open(AlbumAddComponent, {
      width: '1050px',
      data: { state: this.state, selectedAlbum : album }
    });
  }

  deleteAlbum(event, album) {
    //console.log("Album Delete Clicked");
    this.state = 2;
    const dialogRef = this.dialog.open(AlbumAddComponent, {
      width: '1050px',
      data: { state: this.state, album: album }
    });
  }

  async DeleteAlbum(album_) {
    // 0. thumbnail 을 지워준다.
    // 1. file 들을 지워준다.
    // 2. Data 를 지워준다.

    if (album_.thumbnail != "") {
      this.storage.storage.refFromURL(album_.thumbnail).delete();
    }

  }



  numberOfPages() {
    window.scrollTo(0, 0);
    this.numP =[];
    var newP = Math.ceil(this.albums.length / this.pageSize);
    if (newP>0){
      for(var i=0; i<newP; i++){
        var pnum = i+1;
        this.numP.push(pnum);
      }
      this.NowPage = 1;
    }else{
      this.NowPage = 1;
      this.numP.push(1);
    }
  }

  pageJump(my : number){
    this.NowPage =  my;
    window.scrollTo(0, 0);
    //console.log("makeapage", this.NowPage );
  }
}
