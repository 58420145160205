import { Component, OnInit, Input, ChangeDetectorRef, Output } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'upload-task',
  templateUrl: './upload-task.component.html',
  styleUrls: ['./upload-task.component.scss']
})
export class UploadTaskComponent implements OnInit {

  @Input() file: File;
  @Input() file_path: String = "";
  @Input() tag: String = "";

  @Output() thumbnailEvent = new EventEmitter();
  @Output() imageEvent = new EventEmitter();
  @Output() illuEvent = new EventEmitter();
  @Output() downloadEvent = new EventEmitter();
  @Output() colorEvent = new EventEmitter();


  task: AngularFireUploadTask;
  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL;

  constructor(private storage: AngularFireStorage, private db: AngularFirestore) { }

  ngOnInit() {
    this.startUpload();
  }

  startUpload() {
    var path = "";

    if (this.tag == "") {
      console.log("Setting the tag");
      return;
    } else {
      console.log("Tag is : " + this.tag);
    }
    
    path = this.file_path + "/" + `${Date.now()}_${this.file.name}`;


    // Reference to storage bucket
    const ref = this.storage.ref(path);

    // The main task
    this.task = this.storage.upload(path, this.file);

    // Progress monitoring
    this.percentage = this.task.percentageChanges();

    this.snapshot = this.task.snapshotChanges().pipe(
      tap(console.log),
      // The file's download URL
      finalize(async () => {
        this.downloadURL = await ref.getDownloadURL().toPromise();

        this.db.collection('files').add({ downloadURL: this.downloadURL, path });

        switch (this.tag) {
          case "product_thumbnail":
            //this.add_product.setThumnailURL(this.downloadURL);
            this.thumbnailEvent.emit(this.downloadURL);
            break;

          case "product_image":
            this.imageEvent.emit(this.downloadURL);
            break;

          case "product_illu":
            this.illuEvent.emit(this.downloadURL);
            break;

          case "download_file":
            this.downloadEvent.emit(this.downloadURL);
            break;

          case "product_color":
            this.colorEvent.emit(this.downloadURL);
            break;
        }
      }),
    );
  }

  isActive(snapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  }

  file_delete(){
    console.log("delete file clicked");
  }
}
