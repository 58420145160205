import { Injectable } from '@angular/core';

enum Language {
  ENGLISH,
  KOREAN
}

enum LangueCSS{
  ENGLISH = "en",
  KOREAN = "kr"
}

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  now_language = Language.ENGLISH;
  now_languecss = LangueCSS.ENGLISH;
  constructor() { 
  }

  GetNowLanguage(){
    return this.now_language;
  }

  GetNowCssLangue(){
    return this.now_languecss;
  }

  /**
   * 
   * @param input 0, 1, 2 등으로 진행
   */
  SetNowLanguage(input){
    this.now_language = input;
    //this.now_languecss = input;
  }
  SetNowCSS(input){
    this.now_languecss = input;
  }
}
