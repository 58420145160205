import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-product-modal',
  templateUrl: './product-modal.component.html',
  styleUrls: ['./product-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProductModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  
  name = new FormControl('');
  email = new FormControl('');
  comp = new FormControl('');

  // setDownloadLink(input){
  //   this.download_link = input;
  //   console.log("input = " + input);
  //   console.log("download_link = " + this.download_link);
  // }
}
