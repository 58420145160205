import { Component, OnInit } from '@angular/core';
import { Designer } from 'src/app/models/designer';
import { DesignerService } from 'src/app/services/designer.service';
import { MatDialog } from '@angular/material/dialog';
import { AddDesignerComponent } from 'src/app/components/designer/add-designer/add-designer.component';
import { first  } from 'rxjs/operators';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-designer-manager',
  templateUrl: './designer-manager.component.html',
  styleUrls: ['./designer-manager.component.scss']
})
export class DesignerManagerComponent implements OnInit {
  constructor(    public authService: AuthService,private designerService: DesignerService, public dialog: MatDialog) { }

  designers: Designer[];
  isModify = false;
  del  = false;
  modify_Designer: Designer;
  designer: Designer;

  ngOnInit() {
    this.designerService.getDesigners().pipe(first()).subscribe(designers => {
      this.designers = designers;
    })

    this.isModify = false;
  }

  logoutUser() {
    this.authService.logout()
      .then(res => {
        console.log(res);
        console.log('로그아웃 합니다.');
      }, err => {
        window.alert(err.message);
      });
  }

  deleteDesigner(event, designer) {
    this.del = true;
    this.modify_Designer = designer;
    const dialogRef = this.dialog.open(AddDesignerComponent, {
      width: '1050px',
      data: {del : this.del, isModify: this.isModify,  modify_Designer: this.modify_Designer}

    });
    // this.storage.storage.refFromURL(designer.img).delete();
    // this.designerService.deleteDesigner(designer);
  }

  modifyDesigner(event, designer) {
    this.del = false;
    this.isModify = true;
    this.modify_Designer = designer;
    console.log(this.modify_Designer);
    const dialogRef = this.dialog.open(AddDesignerComponent, {
      width: '1050px',
      data: {del : this.del, isModify: this.isModify, modify_Designer: this.modify_Designer }

    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The Modify dialog was closed');
      this.designer = result;
    });
  }

  onAdd(event) {
    this.del = false;
    this.isModify = false;
    const dialogRef = this.dialog.open(AddDesignerComponent, {
      width: '1050px',
      data: {del : this.del, isModify: this.isModify }
    });
  }
}
