import { Injectable } from '@angular/core';
import { auth } from 'firebase/app';
import { User } from 'firebase';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  cist = false;
  cuser: User;
  constructor(
    public afs: AngularFirestore,   // Inject Firestore service,
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
  ) {
    this.afAuth.auth.onAuthStateChanged((user) => {
      this.afAuth.authState.subscribe(user => {
        if (user) {
          this.cuser = user;
          this.cist = true;
          console.log(user);
        } else {
          this.cist = false;

        }
      });
    });
  }

  async elogin(email: string, password: string) {
    return await this.afAuth.auth.signInWithEmailAndPassword(email, password)
  }

  async logout() {
    return await this.afAuth.auth.signOut();
  }

  // Sign in with Google
  async GoogleAuth() {
    return await this.afAuth.auth.signInWithPopup(new auth.GoogleAuthProvider());
  }
  isUserEqual(googleUser, firebaseUser) {
    if (firebaseUser) {
      var providerData = firebaseUser.providerData;
      for (var i = 0; i < providerData.length; i++) {
        if (providerData[i].providerId === auth.GoogleAuthProvider.PROVIDER_ID &&
          providerData[i].uid === googleUser.getBasicProfile().getId()) {
          return JSON.parse(localStorage.getItem('user'));
        }
      }
    }
    return false;
  }

}
