import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestoreDocument, AngularFirestore } from '@angular/fire/firestore';
import { Carousel } from '../models/carousel';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CarouselServiceService {
  carouCollection: AngularFirestoreCollection<Carousel>;
  Carousels: Observable<Carousel[]>;
  CarouDoc: AngularFirestoreDocument;
  SelecCarou: Carousel;

  constructor(public afs: AngularFirestore) {
    this.carouCollection = this.afs.collection('carousels', ref => ref.orderBy('fotos', 'desc'));
    this.Carousels = this.carouCollection
    .snapshotChanges()
    .pipe(map(docs => {
      return docs.map(doc => {
        
        const data = doc.payload.doc.data() as Carousel;
        data.id = doc.payload.doc.id;
        return data;
      })
    }))
  }
  getCarousels() {
    return this.Carousels;
  }

}
