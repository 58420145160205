import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stories-e',
  templateUrl: './stories-e.component.html',
  styleUrls: ['./stories-e.component.css']
})
export class StoriesEComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
