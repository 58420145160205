import { Component, OnInit, Input, Inject } from '@angular/core';
import { Designer } from 'src/app/models/designer';
import { DesignerService } from 'src/app/services/designer.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage'
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';


@Component({
  selector: 'app-add-designer',
  templateUrl: './add-designer.component.html',
  styleUrls: ['./add-designer.component.scss']
})
export class AddDesignerComponent implements OnInit {

  // @Input() modify_Designer: Designer; // 수정될 디자이너의 정보
  // @Input() isModify: boolean; // 현재 수정 버전인가에 대한 flag
  // -----> File System
  files: File[] = [];

  isSetFile = false;
  file: File;  // designer 등록 사진을 위한 file
  task: AngularFireUploadTask; // upload 를 위한 task
  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL;

  designer: Designer = {
    img: '',
    name: '',
    location: '',
    description: []
  }

  constructor(
    private designerService: DesignerService,
    private storage: AngularFireStorage,
    private db: AngularFirestore,
    private dialogRef: MatDialogRef<AddDesignerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    console.log("[now state] : ", this.data);
  }

  onSubmit(event) {
    if (this.data.isModify == true) {
      this.startUpload();
    } else if (this.data.isModify == false) {
      if (this.file != null
        && this.designer.name != ''
        && this.designer.location != ''
        && this.designer.description.length != 0) {
        this.startUpload();
      }
    }
  }

  initDesigner() {
    this.designer.img = '';
    this.designer.name = '';
    this.designer.location = '';
    this.designer.description = [];

    if (this.data.isModify) {
      this.data.modify_Designer.img = '';
      this.data.modify_Designer.name = '';
      this.data.modify_Designer.location = '';
      this.data.modify_Designer.description = '';
    }
    this.files = [];
    this.file = null;
  }

  // 파일을 넣으면 this.file 안에 넣어둔다.
  onDrop(files: FileList) {
    if (files.length == 1) {
      this.file = files[0];
    }
  }

  onRealDelete() {
    this.storage.storage.refFromURL(this.data.modify_Designer.img).delete();
    this.designerService.deleteDesigner(this.data.modify_Designer);
    this.dialogRef.close();
  }
  
  onCancel(): void {
    this.dialogRef.close();
  }
  // file Uplad 를 시작해준다.
  startUpload() {
    if (this.data.isModify == true) {
      // url 기준으로 지우고 시작
      if (this.file != null) {
        this.storage.storage.refFromURL(this.data.modify_Designer.img).delete();
        const path = `designer/${Date.now()}_${this.file.name}`; // 경로를 지정해줌.
        const ref = this.storage.ref(path);
        this.task = this.storage.upload(path, this.file); // upload 를 시작하며 task 에 등록해줌.
        this.percentage = this.task.percentageChanges();
        this.snapshot = this.task.snapshotChanges().pipe(
          tap(console.log),
          finalize(async () => {
            this.downloadURL = await ref.getDownloadURL().toPromise();
            this.data.modify_Designer.img = this.downloadURL;
            //  수정하는 부분 들어가줘야함.
            this.designerService.modifyDesinger(this.data.modify_Designer);
            this.initDesigner();
            if ((await this.task).bytesTransferred == (await this.task).totalBytes) {
              console.log((await this.task).bytesTransferred, "=", (await this.task).totalBytes, "now same bytes");
              this.dialogRef.close();
            }
          })
        )
      } else {
        console.log("same url img");
        this.designerService.modifyDesinger(this.data.modify_Designer);
        this.initDesigner();
        this.dialogRef.close();
      }
    } else {
      const path = `designer/${Date.now()}_${this.file.name}`; // 경로를 지정해줌.
      const ref = this.storage.ref(path);
      this.task = this.storage.upload(path, this.file); // upload 를 시작하며 task 에 등록해줌.
      this.percentage = this.task.percentageChanges();
      this.snapshot = this.task.snapshotChanges().pipe(
        tap(console.log),
        finalize(async () => {
          console.log(this.percentage);
          this.downloadURL = await ref.getDownloadURL().toPromise();
          this.designer.img = this.downloadURL;
          this.designerService.addDesigner(this.designer);
          this.initDesigner();
          if ((await this.task).bytesTransferred == (await this.task).totalBytes) {
            console.log((await this.task).bytesTransferred, "=", (await this.task).totalBytes, "now same bytes");
            this.dialogRef.close();
          }
          //console.log("downloadURL : " + this.downloadURL);
        })
      )
    }
  }
}
