import { Component, OnInit } from '@angular/core';
import { Block } from 'src/app/models/block';
import { BlockService } from 'src/app/services/block.service';
import { first } from 'rxjs/operators';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-blocks',
  templateUrl: './blocks.component.html',
  styleUrls: ['./blocks.component.css']
})
export class BlocksComponent implements OnInit {

  blocks : Block[];

  constructor(private BlockService : BlockService, private languageService: LanguageService) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.BlockService.getBlocks().pipe(first()).subscribe(blocks => {
      this.blocks = blocks;
     
    })
  
  }

onHref(link){
  var a = document.createElement('a');
  a.href = link;
  a.setAttribute('href', link);
  a.click();
  a.remove();
}
}
