import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, } from '@angular/fire/firestore';

import { Item } from '../models/Item';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class ItemService {
  itemsCollection: AngularFirestoreCollection<Item>;
  items: Observable<Item[]>;
  itemDoc : AngularFirestoreDocument;

  constructor(public afs: AngularFirestore) {
    this.itemsCollection = this.afs.collection('items', ref => ref.orderBy('title', 'asc'));
    
    //this.items = this.afs.collection('items').valueChanges();
    
    // this.items = this.afs.collection('items').snapshotChanges().map(changes => {
    //   return changes.map(a=>{
    //     const data = a.paylod.doc.data() as Item;
    //     data.id = a.payload.doc.id;
    //   })
    // })

    this.items = this.itemsCollection
    .snapshotChanges()
    .pipe(map(docs=>{
      return docs.map(doc=>{
        const data = doc.payload.doc.data() as Item;
        data.id = doc.payload.doc.id;
        return data;
      })
    }))

  }

  getItems() {
    return this.items;
  }

  addItem(item: Item){
    this.itemsCollection.add(item);
  }

  deleteItem(item : Item){
    console.log(item);
    this.itemDoc = this.afs.doc('items/' + item.id);
    this.itemDoc.delete();
  }
}

