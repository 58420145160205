import { Component, ViewEncapsulation, HostListener, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { slide } from 'src/app/animation';
import { background } from 'src/app/animation';
import { Observable } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    slide, background
  ]
})


export class AppComponent implements OnInit {
  now_scroll_value = 0;
  before_scroll_value = 0;
  public isDown = false;
  lanEN = true;
  langg = "KR";

  constructor(private meta: Meta, private languageService : LanguageService) {
    this.meta.addTag({ name: 'viewport', content: "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" });
  }

  ngOnInit() {
    
    window.addEventListener('scroll', this.onWindowScroll, true);
  }

  changelan(){
    if(this.lanEN === false){
      this.lanEN = true;
      this.langg = "KR"
      this.languageService.SetNowLanguage(0);
      this.languageService.SetNowCSS("en");
    }else{
      this.lanEN = false;
      this.langg = "EN"
      this.languageService.SetNowLanguage(1);
      this.languageService.SetNowCSS("kr");
    }
    window.scrollTo(0, 0);
    console.log(this.languageService.GetNowLanguage());
  }

  get shit() {
    return this.isDown ? 'down' : 'up'
  }

 @HostListener("document:scroll", ["$event"]) onWindowScroll(e) {
    // event.preventDefault();
    // this.scrollWidth = (event.target as HTMLElement).scrollWidth - (event.target as HTMLElement).clientWidth;
    // this.scrolled = ((event.target as HTMLElement).scrollLeft / this.scrollWidth) * 100;
    //console.log(this.scrolled);
    this.now_scroll_value = window.pageYOffset;
    if (this.now_scroll_value != this.before_scroll_value) {
      if (this.now_scroll_value - this.before_scroll_value > 10) {
        this.isDown = true;
      }
      else if (this.before_scroll_value - this.now_scroll_value > 10) {
        this.isDown = false;
      }
      this.before_scroll_value = this.now_scroll_value;
    }
  }



}
