import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestoreDocument, AngularFirestore } from '@angular/fire/firestore';
import { Main } from '../models/main';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MainService {
  MainCollection: AngularFirestoreCollection<Main>;
  Mains: Observable<Main[]>;
  MainDoc: AngularFirestoreDocument;

  constructor(public afs: AngularFirestore) {
    this.MainCollection = this.afs.collection('main');//, ref => ref.orderBy('mainvideo', 'asc'));
    this.Mains = this.MainCollection
    .snapshotChanges()
    .pipe(map(docs=>{
      return docs.map(doc=>{
        const data = doc.payload.doc.data() as Main;
        data.id = doc.payload.doc.id;
        return data;
      })
    }))
  }

  getMains(){
    return this.Mains;
  }

  modifyMain(Main : Main){
    this.MainDoc = this.afs.doc('main/' + Main.id);
    this.MainDoc.update(Main);
  }

}
