import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './components/admin/admin.component';
import { MainComponent } from './components/main/main.component';
import { MainEComponent } from './components/main-e/main-e.component';
import { ItemsComponent } from './components/items/items.component';
import { StoriesComponent } from './components/stories/stories.component';
import { UploaderComponent } from './components/uploader/uploader.component';
import { ProductsComponent } from './components/product/products/products.component';
import { ProductDetailViewComponent } from './components/product/product-detail-view/product-detail-view.component';
import { DesignersComponent } from './components/designer/designers/designers.component';
import { AlbumViewComponent } from './components/album/album-view/album-view.component';

import { ProductManagerComponent } from './components/product/product-manager/product-manager.component';
import { DesignerManagerComponent } from './components/designer/designer-manager/designer-manager.component';
import { CollectionManagerComponent } from './components/collection/collection-manager/collection-manager.component'
import { AlbumManagerComponent } from './components/album/album-manager/album-manager.component';
import { BlocksManagerComponent} from './components/blocks/blocks-manager/blocks-manager.component';

import { CollectionsComponent } from './components/collection/collections/collections.component';
import { CollectionDetailComponent } from './components/collection/collection-detail/collection-detail.component';

import { ContactComponent } from './components/contact/contact.component';

import { TestComponent } from './components/test/test.component';
import { AlbumAddComponent } from './components/album/album-add/album-add.component';
const AppRoutes: Routes = [
    { path: 'test/:id', component: TestComponent },

    { path: '', redirectTo: '/main', pathMatch: 'full' }, // 첫 화면을 login 페이지로 설정
    { path: 'main', component: MainComponent, }, // url 경로가 /main 일때 MainComponent를 보여준다.
    { path: 'ago460_admin_y2020', component: AdminComponent, }, // url 경로가 /login 일때 LoginComponent를 보여준다.

    { path: 'ago460_admin_y2020_p-manager', component: ProductManagerComponent, },
    { path: 'ago460_admin_y2020_d-manager', component: DesignerManagerComponent, },
    { path: 'ago460_admin_y2020_c-manager', component: CollectionManagerComponent, },
    { path: 'ago460_admin_y2020_a-manager', component: AlbumManagerComponent, },
    { path: 'ago460_admin_y2020_b-manager', component: BlocksManagerComponent,},
    { path: 'ago460_admin_y2020_m_edit', component: MainEComponent, },


    { path: 'upload', component: UploaderComponent, }, // url 경로가 /login 일때 LoginComponent를 보여준다.
    { path: 'items', component: ItemsComponent, },
    { path: 'download', component: AlbumViewComponent, },
    { path: 'products-detail-view/:collection/:name', component: ProductDetailViewComponent, },
    { path: 'designers', component: DesignersComponent, },
    
    { path: 'stories', component: StoriesComponent, },
    { path: 'collections', component: CollectionsComponent, },
    { path: 'collection-detail', component: CollectionDetailComponent, },
    { path: 'contact', component: ContactComponent },
    { path: '**', redirectTo: '/main', pathMatch: 'full' } // 잘못된 URL을 사용했을때 Login 페이지로 돌려보냄.
];

export const AppRouterModule = RouterModule.forRoot(AppRoutes, { useHash: true, relativeLinkResolution: 'legacy' });
