import { Component, OnInit, Inject } from '@angular/core';
import { Main } from 'src/app/models/main';
import { MainService } from 'src/app/services/main.service';
import { MatDialog } from '@angular/material/dialog';
import { AddMainEComponent } from 'src/app/components/add-main-e/add-main-e.component';
import { first } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
@Component({
  selector: 'app-main-e',
  templateUrl: './main-e.component.html',
  styleUrls: ['./main-e.component.scss']
})
export class MainEComponent implements OnInit {

  Mains: Main[];
  isModify = false;
  modify_Main: Main;


  constructor(public authService: AuthService,private MainService: MainService, public dialog: MatDialog) { }

  ngOnInit() {

    this.MainService.getMains().pipe(first()).subscribe(Mains => {
      this.Mains = Mains;
    })
  }

  logoutUser() {
    this.authService.logout()
      .then(res => {
        console.log(res);
        console.log('로그아웃 합니다.');
      }, err => {
        window.alert(err.message);
      });
  }

  ModifyDec(event, Main) {
    console.log("change..");
    this.isModify = true;
    this.modify_Main = Main;
    const dialogRef = this.dialog.open(AddMainEComponent, {
      width: '650px',
      data: { isModify: this.isModify, modify_Main: this.modify_Main }

    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.modify_Main = result;
    });
  }




}
