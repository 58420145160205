import { Directive } from '@angular/core';

@Directive({
  selector: '[appISlide]'
})
export class ISlideDirective {

  constructor() { }

}
