import { Component, OnInit } from '@angular/core';
import { Collection } from 'src/app/models/collection';
import { CollectionService } from 'src/app/services/collection.service';
import { Product } from '../../../models/product';
import { ProductService } from 'src/app/services/product.service';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-collection-detail',
  templateUrl: './collection-detail.component.html',
  styleUrls: ['./collection-detail.component.scss']
})
export class CollectionDetailComponent implements OnInit {

  products: Product[];
  collectionName;

  constructor(private collectionService: CollectionService, private productService: ProductService, private router: Router, private languageService : LanguageService) { }

  now_select_collection: Collection;

  ngOnInit() {
    window.scrollTo(0, 0);
    this.now_select_collection = this.collectionService.getSelectCollection();
    this.collectionName = this.now_select_collection.name;

    // ***************** 새롭게 추가된 getFilterProduct 입니다

    this.productService.getFilterProduct("collection", this.collectionName).pipe(first()).subscribe(products => {
      if (!products) {
        console.log("none product");
        return;
      } else {
        this.products = products;
        // console.log(this.products);

        // for(var i = 0 ; i < this.products.length; i++){
        //   console.log(this.products[i]);
        // }
      }
    })
  }

  onSelect(product: Product): void {
    this.productService.setSelectProduct(product);
    // redirect 로 바꿔준다.
    var redirectString = '';
    redirectString = "/products-detail-view/" + product.collection + "/" + product.name;
    //console.log(redirectString);
    this.router.navigate([redirectString]);

  }

}
