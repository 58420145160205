import { Component, OnInit, Input } from '@angular/core';
import { Product } from '../../../models/product';
import { MatDialog } from '@angular/material/dialog';
import { ProductModalComponent } from '../product-modal/product-modal.component';
import { ProductService } from 'src/app/services/product.service';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit {
  @Input() product: Product;

  render_url: "";
  now_num = 0;
  download_url = "";
  isDownload = false;
  titleWithColorName = "";
  products: Product[];
  collectionName;

  constructor(public dialog: MatDialog, public modal: ProductModalComponent, private router: Router, private productService: ProductService) { }

  ngOnInit() {
    this.render_url = this.product.colors[0]['on'];
    this.titleWithColorName = this.product.colors[0]['color'];
    this.download_url = this.product.download;
    this.collectionName = this.product.collection;
    // this.modal.setDownloadLink(this.download_url);
    // console.log("ing??? : " + this.product.download);
    this.productService.getFilterProduct("collection", this.collectionName).pipe(first()).subscribe(products => {
      if (!products) {
        console.log("none product");
        return;
      } else {
        this.products = products;
      }
    })
  }


  onSelect(product: Product): void {
    window.scroll(0,0);
    this.productService.setSelectProduct(product);
    this.render_url = product.colors[0]['on'];
    this.titleWithColorName = product.colors[0]['color'];
    this.download_url = product.download;
    this.collectionName = product.collection;
    // redirect 로 바꿔준다.
    var redirectString = '';
    redirectString = "/products-detail-view/" + product.collection + "/" + product.name;
    //console.log(redirectString);
    this.router.navigate([redirectString]);

  }

  openDialog() {
    const dialogRef = this.dialog.open(ProductModalComponent);
    dialogRef.afterClosed().subscribe(result => {
      this.isDownload = true;
      // console.log(`Dialog result: ${result}`);
    });
  }

  finalsuperDownloadFucntion() {
    window.open(this.download_url);
    this.isDownload = false;
  }

  public setNowClick(i) {
    // console.log(this.product.colors[i]['color']);
    this.titleWithColorName = this.product.colors[i]['color'];
    this.render_url = this.product.colors[i]['on'];
  }
}


//matTooltip="{{cc['color']}}" aria-label="div that displays a tooltip when focused or hovered over"
