import { Component, OnInit, Inject } from '@angular/core';
import { Main } from 'src/app/models/main';
import { MainService } from 'src/app/services/main.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
@Component({
  selector: 'app-add-main-e',
  templateUrl: './add-main-e.component.html',
  styleUrls: ['./add-main-e.component.scss']
})
export class AddMainEComponent implements OnInit {
  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL;
  file_path: string = "";
  task: AngularFireUploadTask;
  Main: Main = {
    mainvideo: '',
    maindesc: '',
    main_script: [],
    carimg: []
  }
  Main_e: Main;

  vFiles: File[] = [];
  vurls: string[];

  files_carousel: File[];
  carousel_url : string[];

  constructor(private MainService: MainService, private storage: AngularFireStorage,
    private dialogRef: MatDialogRef<AddMainEComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.vFiles = [];
    this.vurls = [];
    this.files_carousel =[];
    this.carousel_url =[];

    if (this.data.isModify == true) {
      this.copyValue(this.data.modify_Main);
    }

  }
  copyValue(input_) {
    this.Main = JSON.parse(JSON.stringify(input_));
  }
  delete_video() {
    this.Main.mainvideo = '';
  }
  delete_img(event, index_) {
    this.Main.carimg.splice(index_, 1);
  }
  onDrop(files: FileList, tag: string) {
    switch (tag) {
      case "main_video":
        for (let i = 0; i < files.length; i++) {
          this.vFiles.push(files.item(i));
        }
        break;
      case "main_carousel":
        for (var i = 0; i < files.length; i++) {
          this.files_carousel.push(files[i]);
        }
        break;
    }
  }
  onSubmit(){
    this.modify();
  }
  onCancel() {
    this.dialogRef.close();
  }
  fileUpload(tag, file) {
    return new Promise<void>((resolve, reject) => {
      var path = '';
      if (tag == 'video') {
        path = `videos/${Date.now()}_${file.name}`;
      } else if (tag == 'carousel') {
        path = `carousel/${Date.now()}_${file.name}`;
      }
      var ref = this.storage.ref(path);
      this.task = this.storage.upload(path, file);
      this.snapshot = this.task.snapshotChanges().pipe(
        tap(console.log),
        finalize(async () => {
          var url = await ref.getDownloadURL().toPromise();
          if (tag == 'video') {
            this.Main.mainvideo = url;
          } else if (tag == 'carousel') {
            this.Main.carimg.push(url);
          }
          resolve();
        })
      )
    })
  }

  startUpload(tag, file, index = 0) {
    return new Promise<void>((resolve, reject) => {
      var path = "";
      switch (tag) {
        case "video":
          this.file_path = "videos";

          break;

        case "carousel":
          this.file_path = "carousel";

          break;
      }
      path = this.file_path + "/" + `${Date.now()}_${file.name}`;

      const ref = this.storage.ref(path);
      this.task = this.storage.upload(path, file);
      this.snapshot = this.task.snapshotChanges().pipe(
        tap(console.log),
        finalize(async () => {
          let url = await ref.getDownloadURL().toPromise();
          switch (tag) {
            case "video":
              this.file_path = "videos";
              this.Main.mainvideo = url;
              break;

            case "carousel":
              this.file_path = "carousel";
              this.Main.carimg.push(url);
              break;
          }
          console.log("url : ", url);
          resolve();
        }),
      );
    })
  }

  async modify() {
    if(this.Main.mainvideo == ""  && this.data.modify_Main.mainvideo != "") {
      this.storage.storage.refFromURL(this.data.modify_Main.mainvideo).delete();
    }

    for (var i = 0; i < this.data.modify_Main.carimg.length; i++) {
      let isFound = false;
      for (var m = 0; m < this.Main.carimg.length; m++) {
        if (this.data.modify_Main.carimg[i] == this.Main.carimg[m]) {
          isFound = true;
          break;
        }
      }
      // 만약 찾았다면 넘어감
      if (isFound == true) {
        continue;
      }
      // 찾지 못했다면 삭제!
      else {
        this.storage.storage.refFromURL(this.data.modify_Main.carimg[i]).delete();
      }
    }

    for (var i = 0; i < this.vFiles.length; i++) {
      await this.startUpload('video', this.vFiles[i]);
    }
    for (var i = 0; i < this.files_carousel.length; i++) {
      await this.startUpload('carousel', this.files_carousel[i]);
    }
    await this.MainService.modifyMain(this.Main);
    this.dialogRef.close();
  }

}
