import { trigger, state, transition, style, animate } from '@angular/animations';

const animateIn = '0.15s ease-in';
const animateOut = '0.25s ease-out';

export let slide = trigger('slideMeme', [
  state('up', style({
    overflow: 'hidden',
    opacity: 1,
    //transform: 'scaleY(1)',
    height: "64px"
  })),
  state('down', style({
    overflow: 'hidden',
    opacity: 1,
    //transform: 'scaleY(0)',
    height:"0px"
  })),
  transition('down => up', animate('395ms 0.3ms ease-in-out')),
  transition('up => down', animate('250ms ease-out'))

]);

export let background = trigger('backgroundChange', [
  state('up', style({
    background: 'rgba(255,255,255,1)'
  })),
  state('down', style({
    background: 'rgba(255,255,255,0)'
  })),
  transition('down => up', animate('250ms ease-out')),
  transition('up => down', animate('395ms 0.3ms ease-in-out'))

]);



export let pop = trigger('popOverState', [
  state('show', style({
    opacity: 1,
  })),
  state('hide', style({
    opacity: 0,
  })),
  transition('show => hide', animate('350ms ease-out')),
  transition('hide => show', animate('750ms ease-in'))
])

