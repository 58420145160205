import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Product } from '../../models/product';
import { ProductService } from 'src/app/services/product.service';

import { Main } from 'src/app/models/main';
import { MainService } from 'src/app/services/main.service';
import { LanguageService } from 'src/app/services/language.service';

import { first } from 'rxjs/operators';
import { Router } from '@angular/router';



@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})

export class MainComponent implements OnInit {
  @ViewChild('videoPlayer') videoPlayer;
  public innerWidth: any;
  products: Array<Product> = [];
  Mains: Main[];
  mtmt = true;

  language = 0;

  constructor(private productService: ProductService, private MainService: MainService, private router: Router, private LanguageService : LanguageService) {

  }

  // vPath = 'https://firebasestorage.googleapis.com/v0/b/agolighting-88c6d.appspot.com/o/videos%2F1454144866.mp4?alt=media&token=2449369b-de54-4105-8ead-10a4bab043b8';

  ngOnInit() {
    window.scrollTo(0, 0);
    this.language = this.LanguageService.GetNowLanguage();

    this.MainService.getMains().pipe(first()).subscribe(Mains => {
      this.Mains = Mains;
    })
    this.productService.getProducts().pipe(first()).subscribe(products => {
      if (!products) {
        console.log("none product");
        return;
      } else {
        for (var i = 0; i < 8; i++) {
          var randomValue = Math.random() * products.length;
          if (randomValue >= 27) {
            randomValue = 26;
          } else if (randomValue <= 0) {
            randomValue = 0;
          }
          randomValue = Math.floor(randomValue);

          var isPass = true;
          if (this.products.length > 0) {
            for (var j = 0; j < this.products.length; j++) {
              if (this.products[j] == products[randomValue]) {
                isPass = false;
              }
            }
          }

          if (isPass) {
            this.products.push(products[randomValue]);
          } else {
            // console.log("hi");
            i--;
          }
        }
      }
    });
  }

  onSelect(product: Product): void {
    console.log("select product : ", product);
    var redirectString = '';
    redirectString = "/products-detail-view/" + product.collection + "/" + product.name;
    this.router.navigate([redirectString]);
  }

  // ngAfterViewInit(){

  // }

  toggleVideo(event: any) {
    if (this.videoPlayer.nativeElement.muted == false) {
      this.videoPlayer.nativeElement.muted = true;
      this.mtmt = true;
    } else {
      this.videoPlayer.nativeElement.muted = false;
      this.mtmt = false;
    }
  }
}

