import { Component, OnInit } from '@angular/core';
import { Designer } from 'src/app/models/designer';
import { DesignerService } from 'src/app/services/designer.service';
import { first } from 'rxjs/operators';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-stories',
  templateUrl: './stories.component.html',
  styleUrls: ['./stories.component.css']
})
export class StoriesComponent implements OnInit {
  designers: Designer[];

  constructor(private designerService: DesignerService, private languageService: LanguageService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.designerService.getDesigners().pipe(first()).subscribe(designers => {
      this.designers = designers;
      console.log(this.designers);
    })
  }
}
