import { Component, OnInit } from '@angular/core';
import { Routes, Router } from '@angular/router'
import { AuthService } from '../../services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  form: FormGroup;
  constructor(private router: Router, public authService: AuthService, private formBuilder: FormBuilder) { }


  Designer(event){
    // console.log("Designer Button Clicked");
    this.router.navigate(['/designer-manager']);
  }

  Collection(event){
    // console.log("Collection Button Clicked");
    this.router.navigate(['/collection-manager']);
  }

  Product(event){
    // console.log("Product Button Clicked");
    this.router.navigate(['/product-manager']);
  }

  Album(event){
    this.router.navigate(['/album-manager']);
  }

  logoutUser() {
    this.authService.logout()
      .then(res => {
        console.log(res);
        console.log('로그아웃 합니다.');
      }, err => {
        window.alert(err.message);
      });
  }

  euserLogin(email, password) {
   return this.authService.afAuth.auth.setPersistence('session').then(()=>{
      this.authService.elogin(email, password)
      .then(userCredential => {
        this.authService.cuser = userCredential.user;
        console.log('안전하게 로그인 되었습니다.');
      }, err => {
        window.alert(err.message);
      });
    })
  }
  onChange(event) {
    console.log('changed');
  }
  ngOnInit() {
    this.form = this.formBuilder.group({
      signature: ['', Validators.required]
    });

  }
}
