import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestoreDocument, AngularFirestore } from '@angular/fire/firestore';
import { Designer } from '../models/designer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DesignerService {
  designersCollection: AngularFirestoreCollection<Designer>;
  designers: Observable<Designer[]>;
  designerDoc : AngularFirestoreDocument;

  constructor(public afs: AngularFirestore) {
    this.designersCollection = this.afs.collection('designers', ref => ref.orderBy('name', 'asc'));
    this.designers = this.designersCollection
    .snapshotChanges()
    .pipe(map(docs=>{
      return docs.map(doc=>{
        const data = doc.payload.doc.data() as Designer;
        data.id = doc.payload.doc.id;
        return data;
      })
    }))

  }

  getDesigners() {
    return this.designers;
  }

  addDesigner(designers: Designer){
    this.designersCollection.add(designers);
  }

  modifyDesinger(designer: Designer){
    this.designerDoc = this.afs.doc('designers/' + designer.id);
    this.designerDoc.update(designer);
  }

  deleteDesigner(designers : Designer){
    console.log(designers);
    this.designerDoc = this.afs.doc('designers/' + designers.id);
    this.designerDoc.delete();
  }
}
