import { Component, OnInit } from '@angular/core';
import { Collection} from 'src/app/models/collection';
import { CollectionService } from 'src/app/services/collection.service'
import { first } from 'rxjs/operators';
import { pop } from 'src/app/animation';

@Component({
  selector: 'app-collections',
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.scss'],
  animations : [
    pop
  ]
})
export class CollectionsComponent implements OnInit {

  collections : Collection[];
  isClickedArray : Boolean[] = [];
  constructor(private collectionService : CollectionService) { }
  show = false;
  mobile = false;
  isSelectNum = 0;

  ngOnInit() {
    window.scrollTo(0, 0);

    this.collectionService.getCollections().pipe(first()).subscribe(collections =>{
      if(!collections){
        console.log("il n'y a plus collection");
        return;
      }else{
        // console.log(collections);
        this.collections = collections;
        if (window.screen.width < 768) { // 768px portrait
          this.mobile = true;
        }
        else{
          for(var i = 0 ; i < this.collections.length; i ++){
            this.isClickedArray.push(false);
          }
        }

      }
    })
  }


  get stateName() {
    return this.isClickedArray[this.nowCollectionIndex] ? 'show' : 'hide'
  }

  nowCollectionIndex = 0;
  SetnowCollectionIndex(input_: number){
    this.nowCollectionIndex = input_;
  }

  // toggle() {
  //   this.show = !this.show;
  //   console.log(this.show);
  // }


  onSelect(index : number): void {
    this.show = !this.show;
    this.isClickedArray[index] = !this.isClickedArray[index];
    //console.log(this.show);
    //console.log(this.isClickedArray[index]);
    this.isSelectNum = index;
  }

  onDetailSelect(collection : Collection){
    this.collectionService.setSelectCollection(collection);
  }


}
