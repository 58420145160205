import { Injectable, NgModule } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestoreDocument, AngularFirestore } from '@angular/fire/firestore';
import { Album } from '../models/album';
import { Observable } from 'rxjs';
import { map, refCount } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AlbumService {
  albumCollection: AngularFirestoreCollection<Album>;
  albums: Observable<Album[]>;
  albumDoc: AngularFirestoreDocument;
  selectAlbum: Album;
  constructor(public afs: AngularFirestore) {
    this.albumCollection = this.afs.collection('album',
      ref => ref.orderBy('name', 'asc'));

    this.albums = this.albumCollection.snapshotChanges().pipe(map(docs => {
      return docs.map(doc => {
        const data = doc.payload.doc.data() as Album;
        data.id = doc.payload.doc.id;
        return data;
      })
    }))
  }

  GetAlbums() {
    return this.albums;
  }

  AddAlbum(album: Album) {
    this.albumCollection.add(album);
  }

  ModifyAlbum(album: Album) {
    this.albumDoc = this.afs.doc('album/' + album.id);
    this.albumDoc.update(album);
  }

  DeleteAlbum(album: Album) {
    this.albumDoc = this.afs.doc('album/' + album.id);
    this.albumDoc.delete();
  }

  FindAlbum(tags_) {
    return this.albumCollection.ref.where('tag', 'array-contains-any', tags_).get();
  }
}
