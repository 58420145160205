import { Component, OnInit } from '@angular/core';
import { Designer } from 'src/app/models/designer';
import { DesignerService } from 'src/app/services/designer.service';

@Component({
  selector: 'app-designers',
  templateUrl: './designers.component.html',
  styleUrls: ['./designers.component.scss']
})
export class DesignersComponent implements OnInit {
  designers: Designer[];

  constructor(private designerService: DesignerService) { }

  ngOnInit() {
    this.designerService.getDesigners().subscribe(designers => {
      console.log(designers);
      this.designers = designers;
    })
  }


}
