import { Component, OnInit, OnDestroy, AfterContentInit, ContentChildren, ViewChild, QueryList, ElementRef, HostListener } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SliderItemDirective } from 'src/app/directives/slider-item.directive';
import { ScrollleftService } from '../../services/scrollleft.service';
import { CollectionService } from '../../services/collection.service';
import { ProductService } from '../../services/product.service';
import { first } from 'rxjs/operators';
@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],

})
export class SliderComponent implements AfterContentInit, OnInit, OnDestroy {
  @ContentChildren(SliderItemDirective, { read: ElementRef }) items
    : QueryList<ElementRef<HTMLDivElement>>;
  @ViewChild('slides') slidesContainer: ElementRef<HTMLDivElement>;
  @ViewChild('mpBar') mpBarcon: ElementRef;
  public slidesIndex = 0;
  public copyval = 0;
  public innerWidth: any;
  public nVal = 8;
  public scrollWidth = 0;
  public real_scroll =0;
  public iscrolled  = 0;
  public innerScroll: number;
  public pageScroll: number;
  private sCsc  : ScrollleftService;

  hideBt: boolean;

  get currentItem(): ElementRef<HTMLDivElement> {
    return this.items.find((item, index) => index === this.slidesIndex);
  }

  constructor(public collection_service: CollectionService, public product_service: ProductService, private el :  ElementRef,  sCsc  : ScrollleftService) {
    this.hideBt = false;
    this.sCsc = sCsc;
    this.innerScroll = 0;
    this.pageScroll = 0;
  }


  ngOnInit() {
    window.scrollTo(0,0);
    this.sCsc
            .getScrollAsStream() // Defaults to Document if no Element supplied.
            .subscribe(
                ( percent: number ) : void => {

                    this.pageScroll = percent;

                }
            )
        ;
  }

  ngOnDestroy() {
  }
  ngAfterContentInit() {
    if (this.collection_service.getSelectCollection()) {
      this.product_service.getFilterProduct("collection", this.collection_service.getSelectCollection().name).pipe(first()).subscribe(products => {
       // console.log("this is products length : " + products.length);
        this.nVal = products.length;
        if (this.innerWidth > 1024) {
          this.nVal = this.nVal - 2;
         // console.log(this.innerWidth, this.nVal);
        }
        else if (this.innerWidth> 650){
          this.nVal = this.nVal - 1;
          console.log(this.innerWidth, this.nVal);
        }else{
          this.nVal = this.nVal;
          console.log(this.innerWidth, this.nVal);
        }
      })
    }
  }
  ngAfterViewInit() {
    this.innerWidth = window.innerWidth;
    //console.log("item size - ", this.slidesIndex);

    if (this.innerWidth > 1024) {
      this.nVal = this.nVal - 2;
      //console.log(this.innerWidth, this.nVal);
    }
    else if (this.innerWidth> 650){
      this.nVal = this.nVal - 1;
      console.log(this.innerWidth, this.nVal);
    }else{
      this.nVal = this.nVal;
      console.log(this.innerWidth, this.nVal);
    }

  }

  onClickLeft() {
    if (this.slidesIndex > 0) {
      this.slidesContainer.nativeElement.scrollLeft -=  this.currentItem.nativeElement.offsetWidth;
      this.slidesIndex--;
      console.log(this.slidesIndex);
    }
  }

  onClickRight() {
    if (this.slidesIndex < this.nVal -1) {
      this.slidesContainer.nativeElement.scrollLeft +=  this.currentItem.nativeElement.offsetWidth;
      this.slidesIndex++;
      console.log(this.slidesIndex);
    }
  }

  public recordInnerScroll( percent: number ) : void {
    this.innerScroll = percent;
    //console.log(this.innerScroll);
}
  // @HostListener("document:scroll", ["$event"]) onScroll($event) {
  //   this.scrollWidth = (event.target as HTMLElement).scrollWidth - (event.target as HTMLElement).clientWidth;
  //   this.real_scroll = ((event.target as HTMLElement).scrollLeft / this.scrollWidth) * 100;
  //   //console.log(this.real_scroll);
  //   if (this.real_scroll == NaN || this.real_scroll == 0 || this.real_scroll == undefined) {
  //     this.iscrolled = 0;
  //     console.log("scrolled : ", this.iscrolled);
  //     //this.MatProgressBar.nativeElement ;
  //   } else if (this.real_scroll > 0) {
  //     this.iscrolled = this.real_scroll;
  //   //  console.log(this.slidesContainer);
  //     //console.log("scrolled : ", this.iscrolled);
  //     //this.mpBarcon.nativeElement.value = this.iscrolled;
  //   }
  // }


 }

