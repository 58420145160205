import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


const routes: Routes = [];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }), BrowserModule, BrowserAnimationsModule],
  declarations: [ ],bootstrap: [ ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
