import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {MatPaginatorModule} from '@angular/material/paginator';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DragScrollModule } from 'ngx-drag-scroll';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule, AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AppRouterModule } from './app.router.module';

// Directive ------>
import { DropzoneDirective } from './directives/dropzone.directive';
import { ScrollVDirective } from './directives/scroll-v.directive';

// Services ------>
import { ItemService } from './services/item.service';
import { ProductService } from './services/product.service';
import { DesignerService } from './services/designer.service';
import { CollectionService } from './services/collection.service';
import { CarouselServiceService } from './services/carousel-service.service';
import { ScrollleftService } from './services/scrollleft.service';
import { MainService } from './services/main.service';
import { BlockService } from 'src/app/services/block.service';
import { AuthService } from 'src/app/services/auth.service';

// Components ------>
import { AdminComponent } from './components/admin/admin.component';
import { MainComponent } from './components/main/main.component';
import { ItemsComponent } from './components/items/items.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { AddItemComponent } from './components/add-item/add-item.component';
import { StoriesComponent } from './components/stories/stories.component';
import { UploadTaskComponent } from './components/upload-task/upload-task.component';
import { UploaderComponent } from './components/uploader/uploader.component';
import { ProductsComponent } from './components/product/products/products.component';

import { DesignersComponent } from './components/designer/designers/designers.component';
import { AddDesignerComponent } from './components/designer/add-designer/add-designer.component';
import { ProductDetailComponent } from './components/product/product-detail/product-detail.component';
import { SliderItemDirective } from './directives/slider-item.directive';
import { SliderComponent } from './components/slider/slider.component';
import { CollectionsComponent } from './components/collection/collections/collections.component';
import { ProductDetailViewComponent } from './components/product/product-detail-view/product-detail-view.component';
import { AddProductComponent } from './components/product/add-product/add-product.component';
import { CollectionDetailComponent } from './components/collection/collection-detail/collection-detail.component';
import { ContactComponent } from './components/contact/contact.component';
import { ProductModalComponent } from './components/product/product-modal/product-modal.component';
import { CarouselConComponent } from './components/carousel-con/carousel-con.component';
import { ISlideDirective } from './directives/i-slide.directive';
import { CollectionManagerComponent } from './components/collection/collection-manager/collection-manager.component';
import { AddCollectionComponent } from './components/collection/add-collection/add-collection.component';
import { DesignerManagerComponent } from './components/designer/designer-manager/designer-manager.component';
import { TestComponent } from './components/test/test.component';
import { ProductManagerComponent } from './components/product/product-manager/product-manager.component';
import { MainEComponent } from './components/main-e/main-e.component';
import { AddMainEComponent } from './components/add-main-e/add-main-e.component';
import { AlbumViewComponent } from './components/album/album-view/album-view.component';
import { AlbumAddComponent } from './components/album/album-add/album-add.component';
import { AlbumManagerComponent } from './components/album/album-manager/album-manager.component';
import { DownLoadComponent } from './components/down-load/down-load.component';
import { StoriesEComponent } from './components/stories-e/stories-e.component';
import { BlocksComponent } from './components/blocks/blocks/blocks.component';
import { BlocksManagerComponent } from './components/blocks/blocks-manager/blocks-manager.component';
import { AddBlocksComponent } from './components/blocks/add-blocks/add-blocks.component';


@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    MainComponent,
    ItemsComponent,
    NavbarComponent,
    AddItemComponent,
    StoriesComponent,
    UploadTaskComponent,

    UploaderComponent,
    DropzoneDirective,
    ScrollVDirective,
    ProductsComponent,
    DesignersComponent,
    AddDesignerComponent,
    ProductDetailComponent,
    SliderItemDirective,
    SliderComponent,
    CollectionsComponent,
    ProductDetailViewComponent,
    AddProductComponent,
    CollectionDetailComponent,
    ContactComponent,
    ProductModalComponent,
    CarouselConComponent,
    ISlideDirective,
    CollectionManagerComponent,
    AddCollectionComponent,
    DesignerManagerComponent,
    TestComponent,
    ProductManagerComponent,
    MainEComponent,
    AddMainEComponent,
    AlbumViewComponent,
    AlbumAddComponent,
    AlbumManagerComponent,
    DownLoadComponent,
    StoriesEComponent,
    BlocksComponent,
    BlocksManagerComponent,
    AddBlocksComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase, 'angularfs'),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    AppRoutingModule,
    AppRouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    MatCardModule,
    MatTooltipModule,
    MatDialogModule,
    MatCheckboxModule,
    MatInputModule,
    MatFormFieldModule,
    MatProgressBarModule,
    MatButtonModule,
    MatRadioModule,
    MatPaginatorModule,
    NgbModule,
    DragScrollModule
  ],
  providers: [AuthService,ItemService, BlockService, ProductService, MainService, DesignerService, CollectionService, CarouselServiceService, ScrollleftService, AddProductComponent, ProductDetailComponent, ProductModalComponent, AlbumAddComponent],
  bootstrap: [AppComponent],
  entryComponents: [ProductDetailComponent, ProductModalComponent, AddDesignerComponent, AddCollectionComponent, AddProductComponent, AddMainEComponent, AlbumAddComponent]
})
export class AppModule {


}
