import { Component, OnInit } from '@angular/core';
import { Collection } from 'src/app/models/collection';
import { CollectionService } from 'src/app/services/collection.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatDialog } from '@angular/material/dialog';
import { AddCollectionComponent } from 'src/app/components/collection/add-collection/add-collection.component';
import { CollectionDetailComponent } from '../collection-detail/collection-detail.component';
import { first } from 'rxjs/operators';
import { AuthService } from '../../../services/auth.service';
@Component({
  selector: 'app-collection-manager',
  templateUrl: './collection-manager.component.html',
  styleUrls: ['./collection-manager.component.scss']
})
export class CollectionManagerComponent implements OnInit {

  constructor(
    public authService: AuthService,
    private collectionService: CollectionService,
    private storage: AngularFireStorage,
    public dialog: MatDialog
  ) { }

  collections: Collection[];
  isModify = false; // 수정 페이지인가?
  isDelete = false; // 삭제 페이지인가?
  modify_Collection: Collection;

  ngOnInit() {
    this.collectionService.getCollections().subscribe(collections => {
      this.collections = collections;
    })
    this.isModify = false;
  }

  logoutUser() {
    this.authService.logout()
      .then(res => {
        console.log(res);
        console.log('로그아웃 합니다.');
      }, err => {
        window.alert(err.message);
      });
  }
  deleteCollection(event, collection) {
    this.isModify = false;
    this.isDelete = true;
    this.modify_Collection = collection;
    const dialogRef = this.dialog.open(AddCollectionComponent, {
      width: '1050px',
      data: { isDelete: this.isDelete, isModify: this.isModify, modify_Collection: this.modify_Collection }

    });


  }

  modifyCollection(event, collection) {
    this.isModify = true;
    this.isDelete = false;
    this.modify_Collection = collection;
    // console.log(collection);
    const dialogRef = this.dialog.open(AddCollectionComponent, {
      width: '1050px',
      data: { isDelete: this.isDelete, isModify: this.isModify, modify_Collection: this.modify_Collection }

    });
    dialogRef.afterClosed().subscribe(result => {
      this.modify_Collection = result;
    });
  }

  onAdd(event) {
    this.isModify = false;
    this.isDelete = false;
    const dialogRef = this.dialog.open(AddCollectionComponent, {
      width: '1050px',
      data: { isDelete: this.isDelete, isModify: this.isModify }
    });
  }
}
